* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* overflow: hidden; */
}
.folders{
  padding: 30px 30px 10px 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;

}
/* .folders a{
  cursor: pointer;
  font-weight: 300;
  font-size: 20px;
  color: #424242;
  list-style: none;
  text-decoration: none;
} */
.titleName{
  padding: 20px 30px 5px 100px;
  background-color: rgb(220, 220, 220);
  margin: 10px; 
  border-radius: 10px;
}
.titleName p{
  font-size: 25px;
}
.underline {
  width: calc(100% - 60px); 
  margin: 0 auto;
  height: 1px; 
  background-color: #d6d6d6; 
}



/* .link {
  text-decoration: none;
  color: #333;
  margin-right: 10px; 
} */

/* .link:hover {
  color: #555; 
} */

/* .activeLink {
  color: #ff0000 !important;
  font-weight: bold;
} */

/* .folder a.active {
  border-bottom: 2px solid #555; 
  padding-bottom: 5px; 
  font-weight: 600;
  color: #555 !important; 
} */
/* ClientGallary.module.css */

.navLink {
text-decoration: none;
color: #333333d4;
/* background: #e5e5e5; */
border-radius: -1px;
/* border: 1px solid #424242; */
/* padding: 8px 19px; */
padding: 5px 10px;
}

 .folders .navLink .activate {
  font-weight: bold !important;
  color: blue !important;
}


.container {
  position: relative;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  z-index: 9999; /* Ensure it's on top of other elements */
}


.notAvailable {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
}




/* ///////////////////////////////////////////////////// */
.landingSelection {
height: 100vh;
display: flex;
align-items: center;
}

.landingPartition {
display: grid;
grid-template-columns: 1fr 1fr;
width: 100%;
height: 100%;
}

.landingNames {
display: flex;
flex-direction: column;
justify-content: center;
padding: 0 15%;
background-color: #fff; /* Add a background color to ensure text visibility */
}

.landingTitle {
font-size: 3rem;
font-weight:300;
margin-bottom: 1rem;
}

.landingSubtitle {
font-size: 1.2rem;
color: #666;
}

.landingPic {
width: 100%;
height: 100%;
overflow: hidden;
display: flex;
justify-content: center;
align-items: center;
}

.landingPic img {
width: 100%;
height: 100%;
object-fit: cover;
object-position: center top; /* This will focus on the top part of the image */
}
/* ///////////////////////////////////////////////////// */

@media (max-width: 450px){
.landingPartition {
  display: block;
  /* grid-template-columns: 1fr 1fr; */
  width: 100%;
  height: 100%;
}

}
.stickyNav {
  position: sticky;
  top: 0;
  background-color: #fff; /* or any color that matches your design */
  z-index: 1000;
  padding: 10px 0;
}

.folders {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 10px;
}


.content {
  margin-top: 20px; /* Add some space below the sticky nav */
}



.navLink {
  text-decoration: none;
  color: #333333d4;
  padding: 5px 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.activate {
  /* background-color: #e5e5e5;  */
  color: #333;
  font-weight: 600;
  /* border-radius: 5px; */
  /* border: 1px solid #424242; */
  /* padding: 8px 23px; */
}

/* Optional: Hover effect for non-active links */
.navLink:hover {
  background-color: #f0f0f0; 
  color: #000; 
}
