@import "~react-image-gallery/styles/css/image-gallery.css";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* overflow: hidden; */
}

.sendbtn{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  background-color: #dedede;
  color: #0a0a0a;
  padding: 2px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  gap: 5px;
}
.sendbtn span{
  padding-top: 5px; 
}
.selectionBtn {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5px;
    margin-right: 100px;
}

.selectionBtn select {
    background-color: #fff;
    color: #515151;
    padding: 2px 4px;
    border-radius: 5px;
    border-color: #0d0d0d;
    font-size: 15px;
    outline: none;
}

.image_container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 30px;
}

.image {
    position: relative;
    width: 470px;
    height: 550px;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
}

.selected {
    transform: scale(0.9);
    border: 5px solid #07a02b;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.checkmark {
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 10px;
    z-index: 1;
}

.heartIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
}

.heartIcon svg {
    display: block;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center; /* Align horizontally */
    align-items: center; /* Align vertically */
    z-index: 1000;
}

.modalContent {
    background-color: white;
    padding: 30px 70px 30px 70px;
    border-radius: 5px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);  */
    box-shadow: #424242; 
}

.modalContent h2 {
    margin-top: 0;
}

.modalContent p {
    margin-bottom: 10px;
}

.modalContent button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modalContent button:hover {
    background-color: #f0f0f0; /* Light gray background on hover */
}

.message {
    font-size: 14px;
    padding-bottom: 25px;
    font-weight: 300;
    color: #4a90dc;
    font-style: italic;
}

.modalContent button:nth-child(2) {
    background-color: #007bff;
    color: #f0f0f0;
}

.deletebtn {
    cursor: pointer;
}

.stickyOptions {
    position: sticky;
    top: 60px; /* Adjust this value based on the height of your folders navbar */
    background-color: #fff; /* or any color that matches your design */
    z-index: 999;
    padding: 10px 0;
}



