* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* overflow: hidden; */
}

body {
   font-family: "Cabin", sans-serif;
    background-color: #fff;
    /* height: 100vh;  */
}


/* AlbumSubPage.module.css */

.gallery {
    padding: 20px;
    margin: 0px 20px;
}

.myMasonryGrid {
    display: flex;
    margin-left: -16px; /* Adjust for the gap */
    width: auto;
}

.myMasonryGridColumn {
    padding-left: 16px; /* Adjust for the gap */
    background-clip: padding-box;
}

.galleryItem {
    position: relative;
    margin-bottom: 16px;
}

.galleryImage {
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional: add rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a shadow */
    transition: transform 0.2s; /* Smooth transform effect */
}

.galleryImage:hover {
    transform: scale(1.05); /* Scale up on hover */
}

.previewOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px); /* Blurs the background */
    overflow: auto; /* Allows scrolling if content is too large */
}

.preview {
    position: relative;
    max-width: 80%;
    max-height: 80%;
    animation: fadeIn 0.2s ease-in-out;
}

.preview img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
