@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.backdropie {
    width: auto;
    height: 100vh;
    /* background: rgb(46, 46, 46);
    background: linear-gradient(0deg, rgba(46, 46, 46, 1) 29%, rgba(0, 91, 237, 1) 100%); */

    background: rgb(46, 46, 46);
    background: linear-gradient(0deg, rgba(46, 46, 46, 1) 36%, rgba(0, 91, 237, 1) 100%);
}

.centerContainer {
    /* margin: 60px; */
    padding-top: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-content {
    width: 1248px;
    height: 595px;



    /* background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(6.5px);
    -webkit-backdrop-filter: blur(6.5px);
    border-radius: 79px;
    border: 1px solid rgba(255, 255, 255, 0.18); */

    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 32px 0 rgba(93, 94, 99, 0.37);
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(5.5px);
    border-radius: 80px;
    border: 1px solid rgba(255, 255, 255, 0.18);


    color: #fff;
    /* display: flex;
    justify-content: center; */
}

.DownloadHead {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
}

.DownloadHead p {
    text-align: center;
    font-size: 90px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.DownloadDetailing {
    width: 800px;
    /* text-align: justify; */
    text-align: center;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.DownloadDetailing p {
    padding-top: 10px;
    font-size: 1.3rem;
    font-weight: 300;
    font-family: "Lato", sans-serif;
    line-height: 28px;
    /* display: flex;
    align-items: center; */
}
.DownloadDetailing p span{
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
.DownloadDetailing p span a{
    text-decoration: none;
    color: #fff;
}
.DownloadDetailing p span a:hover{
    text-decoration: none;
    color: #70a2f3;
}
.Download_logo {
    /* padding: 20px 0px 0px 55px; */
    /* padding: 15px 0px 0px 55px; */
    position: relative;
}

.Logos {
    position: absolute;
    left: 54px;
    top: 23px;
}

.Logos li {
    list-style: none;
}
.Logos li a {
    text-decoration: none;
}
.Logos img {
    max-width: 150px;
}

.CenterConsole {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    gap: 20px;
}
.Windows-choose {
    border: 0.5px solid #c2c1c1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 233px;
    height: 42px;
    background: rgb(255 255 255 / 0%);
    /* box-shadow: 0 8px 32px 0 rgba(85, 85, 86, 0.37); */
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    border-radius: 18px;

}
.Windows-choose p{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.DownloadBtn {
    border: 0.5px solid #70a2f3;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 42px;
    border-radius: 18px;
    cursor: pointer;
    /* background: rgb(0, 52, 135);
    background: linear-gradient(0deg, rgb(3 65 162 / 87%) 28%, #007bff 100%); */
    background-color: #005BED;
}
.DownloadBtn a{
    text-decoration: none;
    color: #fff;
}
.DownloadBtn p{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}