/* Reset and Base Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f5f7fa;
    color: #333;
  } */
  
  /* Container */
  .confirm-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 400px;
    height: 100vh;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    gap: 20px;
  }
  
  /* Image Styling */
  .image-wrapper {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid #07cb07;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Details Section */
  .confirm-details {
    text-align: center;
  }
  
  .name {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
  }
  
  .phone {
    font-size: 1rem;
    color: #555;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .whatsapp-icon {
    color: #25D366;
    font-size: 1.2rem;
  }
  
  /* Feedback Messages */
  .error-message,
  .success-message {
    width: 100%;
    padding: 10px 15px;
    border-radius: 8px;
    text-align: center;
    font-size: 0.9rem;
  }
  
  .error-message {
    background-color: #ffe5e5;
    color: #d8000c;
    border: 1px solid #d8000c;
  }
  
  .success-message {
    background-color: #e5ffe5;
    color: #4F8A10;
    border: 1px solid #4F8A10;
  }
  
  /* Submit Button */
  .submit-button {
    width: 100%;
    padding: 12px 20px;
    background-color: #07cb07;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #05a905;
  }
  
  .submit-button:disabled,
  .submit-button.disabled {
    background-color: #a5d6a7;
    cursor: not-allowed;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .confirm-container {
      width: 95%;
      padding: 15px;
    }
  
    .image-wrapper {
      width: 120px;
      height: 120px;
    }
  
    .name {
      font-size: 1.3rem;
    }
  
    .phone {
      font-size: 0.95rem;
    }
  
    .submit-button {
      padding: 10px 18px;
      font-size: 0.95rem;
    }
  }
  