@import "~react-image-gallery/styles/css/image-gallery.css";
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* overflow: hidden; */
}


body {
   font-family: "Cabin", sans-serif;
    background-color: #fdfdfd;
}

.topHead p{
  font-size: 25px;
  align-items: center;
  padding: 30px 0px 10px 100px;
  justify-content: flex-start;
  font-weight: 300; 
  width: 100%;
  color: #000;
  background-color: #d3d3d3;
}
.Header{
  height: 200px;
  width: 100%;
 
}
.selectionBtn{
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  padding: 5px;
  margin: 50px 100px;
}
.selectionBtn select{
  background-color: #007bff;
  color: #fff;
  padding: 2px 4px;
  border-radius: 5px;
  border-color: #007bff;
  font-size: 15px;
  outline: none;
}
/* .selectionBtn select:hover {
  background-color: #eaeaea;
  color: #000000;
} */
/* .selectionBtn {
  display: inline-block;
  margin-right: 10px;
}

.selectionBtn select {
  padding: 8px 16px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  cursor: pointer;
} */

/* .selectionBtn select:hover {
  background-color: #eaeaea;
  color: #007bff;
}

.selectionBtn select:focus {
  outline: none;
  border-color: #007bff;
} */

/* .image_container{
  padding-top: 100px;
  max-width: 1500px;
  margin: 2rem auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.image{
  width: 100%;
  height: 20rem;
  cursor: pointer;
}

.image img{
  width: 100%;
  height: auto;
  object-fit: cover;
} */


.image_container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; 
  padding: 10px 30px;
  /* background-color: #000000; */
}

.image{
  position: relative;
  width: 280px; 
  height: 300px; 
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
}
.selected {
  transform: scale(0.9);
  border: 5px solid #07a02b;
}


.image img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.checkmark {
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 10px #07a02b;
  z-index: 1; /* Ensure it's above the image */
}
