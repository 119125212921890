* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
.ClientListouter{
  width: 90%;
  background-color: #dad9d9ea;
  border-radius: 10px;
  height: fit-content;
  padding: 30px;
}
.outerbody{
  padding: 10px;
}
.eventDate{
  display: flex;
  align-items: center;
  justify-content: space-between; 
  cursor: pointer;
}
.dropdownlist{
  /* transition: all 0.4s; */
  /* transform: rotate(0.5turn); */
  overflow: hidden;
  cursor: pointer;
  /* transition: transform 2s; */
}
.dropdownlistActive{
transform: rotate(0.5turn);
  overflow: hidden;
  cursor: pointer;
  /* transition: transform 2s; */
  /* transform:none; */
} 
.eventDate p{
color: #000;
font-size: 18px;
font-weight: 600;
}
.numProjects{
 color: #424242;
 font-size: 15px;
 padding-left: 20px;
 font-weight: 300;
}
.clientName{
  display: flex;
  /* align-items: center; */
  justify-content:space-between;
  cursor: pointer;
  width: 100%;
  height: fit-content;
  padding: 15px;
  background-color: #fff;
  margin: 20px 0px 10px 0px;
  border-radius: 10px;
  /* color: #057475; */
}
.clientName p{
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px 10px 10px 20px;
}
.clientName:hover{
  /* background-color: #509293; */
  /* background-color: #0088dd; */
  background-color: #4274F6;
  color: #fff;
}
.clientName:hover .deleteIcon {
  color: #fff;
}
.clientName p:hover{
  color: #fff;
}
.usersList{
  display: none;
}
.userListShow{
  display: contents;

}

.Deatailing {
  display: flex;
  align-items: center;
}
.DeatailingInside p{
  font-size: 13px;
  padding: 0px 25px 0px 0px;
  font-style: italic;
}

.ldsDefault {
  /* display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; */

  position: fixed;
  top: 50%;
  left: 55%; 
}
.ldsDefault div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #333333c1 ;
  border-radius: 50%;
  animation: ldsDefault 1.2s linear infinite;
}
.ldsDefault div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.ldsDefault div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.ldsDefault div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.ldsDefault div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.ldsDefault div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.ldsDefault div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.ldsDefault div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.ldsDefault div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.ldsDefault div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.ldsDefault div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.ldsDefault div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.ldsDefault div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes ldsDefault {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}


/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.modalContent {
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.modalContent p {
  font-size: 18px;
  margin-bottom: 20px;
}

.modalActions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.cancelBtn, .confirmBtn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.cancelBtn {
  background-color: #6c757d;
  color: white;
}

.confirmBtn {
  background-color: #dc3545;
  color: white;
}

.cancelBtn:hover, .confirmBtn:hover {
  opacity: 0.8;
}
