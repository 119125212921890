* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.playground {
    display: grid;
    grid-template-columns: 2fr 6fr;
}

.sideOperations {
    background-color: #D9D9D9;
    /* background-color: aquamarine; */
    height: 100vh;
}

.mainOperations {
    /* background-color: tomato; */
    height: 100vh;

}

.searchbar {
    padding: 50px 20px 20px 30px;
}

.AddProjectOpt p {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.projectsOpt {
    width: 350px;
padding: 20px 20px 20px 30px;
}
.dateOpt{
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 15px;
}
.dateOpt p{
    font-size: 15px;
}