* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.clientTitle {
    margin: 2%;
    padding: 1%;
    border-radius: 15px;
    background: #F3F1F1;

    color: #555;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.topClients {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 47px;
}

.topClients span {
    padding-top: 3px;
    cursor: pointer;
}

.PhotoSelect {
    display: grid;
    grid-template-columns: 3fr 1.5fr;
    padding: 25px;
    gap: 30px;
}

.leftSection {

    display: flex;
    flex-direction: column;
    gap: 20px;
}



.editPages {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px 0px 10px 20px;
}

.editPages img {
    padding-left: 10px;
    cursor: pointer;
}

.editPages p {
    color: #0F7EBC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.publicPage {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    flex-direction: column;
    padding: 5px 0px 10px 20px;
}

.publicPage p {
    color: #0F7EBC;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.publicPage h3 {
    color: #7B7B7B;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.publicPage img {
    font-size: 18px;
    padding-left: 10px;
}

.sectionOne {
    border-radius: 0px 0px 15px 15px;
    border: 1px solid #E5E3E3;
    background: #FFF;
    display: grid;
    grid-template-columns: 2fr 3fr 1.5fr;
    width: auto;
    height: 297px;
}

.LefoffLeft {
    position: relative;

}

.CenteroffLeft {
    display: flex;
    align-items: center;
    justify-content: center;

}

.centerImg {
    display: flex;   
}

.WedImgs {
    width: 342px;
    height: 195px;
    border-radius: 0px 0px 0px 12px;
    border: 1px solid #E5E3E3;
}
.RightoffLeft{
    position: relative;
}
.shareBtn{
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 5px 20px 10px 0px;
}
.shareBtn p span{
    cursor: pointer;
}
.RightoffLeft p {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0F7EBC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.RightoffLeft img{
 padding-left: 10px;
}
.sectionTwo {

    border-radius: 15px 15px 15px 15px;
    border: 1px solid #E5E3E3;

    background: #FFF;
    width: auto;
    height: fit-content;
}

.sectionThree {
    border-radius: 15px 15px 15px 15px;
    border: 1px solid #E5E3E3;
    background: #FFF;
    width: auto;
    height: fit-content;
}
.rightSection{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.rightSectionOne {
    width: auto;
    height: fit-content;
    border-radius: 15px;
    border: 1px solid #E5E3E3;
    background: linear-gradient(132deg, #FFF 0.14%, rgba(223, 223, 223, 0.00) 104.28%);
}

.photoHead {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 824px;
    height: 67px;
    border-radius: 14px 14px 0px 0px;
    background: #E5E3E3;
}

.photoHead p {
    padding-left: 10px;

}

.contentHead {
    margin: 0;
    color: #7B7B7B;
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-top: 8px;
}

.photoHead span {
    padding-top: 5px;
}

.Contents {
    padding: 10px 20px 20px 20px;
}

.Contents p {
    color: #7B7B7B;
    font-weight: 400;
    font-size: 20px;
}

.ContentsInside p {
    font-size: 15px;
    padding-top: 13px;
    font-weight: 400;
    line-height: 25px; 
}

.financialsBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    width: 133px;
    margin-top: 24px;
    height: 32px;
    border-radius: 9px;
    cursor: pointer;
    background: #4274F6;

}

.ContentsInside p span {
    padding-right: 8px;
}

.photoBody ul {
    padding: 20px 0px 20px 50px;
}

.photoBody li {
    cursor: pointer;
    margin-top: 20px;
    list-style: none;
    width: fit-content;
}

.photoBody li a {
    list-style: none;
    color: #6B6A6A;
    text-decoration: none;
}

.photoBody li span {
    padding-right: 5px;
}

.addFolder {
    padding: 8px 0px 8px 50px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.addFolder p {
    cursor: pointer;
    color: #0575C6;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.horizontal_line {
    border-top: 1px solid #E5E3E3;
    margin: 5px 50px;

}

.bottomFolder {
    display: flex;
    gap: 50px;
    align-items: center;
    padding: 0px 0px 8px 50px;
}

.bottomFolder p {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 3px;
    color: #9E9696;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.newFolderInputBox {
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 42px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 999; 

    input {
      width: 80%;
      padding: 8px;
      margin-bottom: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }

    button {
      width: 100%;
      padding: 8px;

      width: 142px;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }

.newFolderInputBox {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 999; 
    width: 30%; 
  }

  .newFolderInputBox input,
  .newFolderInputBox button {
    width: 100%;
    padding: 8px;
    outline: none;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  .buttonArray{
    display: flex;    
    gap: 10px;
    padding-top: 12px;
  }
  .buttonArray button {
    width: 142px;

    color: #fff;
    border-radius: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .buttonArray button:nth-child(1) {
    background-color: #c3c3c3a7; 
    color: #141414;
  }

  .buttonArray button:nth-child(2) {
    background-color:  #4274F6; 
  }
 .title{
    padding-left: 8px;
    font-weight: 500;
    padding-bottom: 10px;
 }
  .sectionTwo .newFolderInputBox {
    top: 45%; 
  }

  .sectionThree .newFolderInputBox {
    top: 50%;
  }
  .selected {
    border: 2px solid #4274F6; 
  }
  .gallaryImg{
    cursor: pointer;
  }

.uploadIcon {
    cursor: pointer;
    width: 50px; 
    height: 100px; 
  }

        .fileUploadLabel{
            display: flex;
            align-items: center;
            justify-content: center;

            border: 1px solid #0F7EBC;
            border-radius: 10px;
            height: 218px;
            width: 309px;
        }
        .fileUploadLabels{
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            cursor: pointer;

            height: 100%;
            width: 100%;
        }

        .centerImg {
            position: relative;
            display: flex;
            width: 100%; 
            height: 200px; 
            background-size: cover; 
            background-position: center; 
            background-repeat: no-repeat; 
            border-radius: 10px;
          }

          .centerImg {
            position: relative;
            display: flex;
            width: 100%; 
            height: 200px; 
            background-size: cover; 
            background-position: center; 
            background-repeat: no-repeat; 
          }

          .fileUploadLabel {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #0F7EBC;
            border-radius: 10px;
            height: 218px;
            width: 309px;
          }

          .fileUploadLabels {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            cursor: pointer;
            height: 100%;
            width: 100%;
            position: relative; 
          }

          .overlay {
            display: none; 
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(211, 211, 211, 0.397); 
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            pointer-events: none; 
          }

          .centerImg:hover .overlay {
            display: flex;

          }

          .cameraIcon {
            font-size: 2em; 
            color: #000; 

          }

          .uploadText {
            font-size: 1em; 
            color: #000; 

            margin-top: 10px;
          }