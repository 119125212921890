
.content-section {
  display: flex;
  padding-top: 100px;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

/* Upload progress box in the top-right corner */
.upload-progress-face {
  position: absolute;
  top: 10px;
  right: 50px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
}

/* Rest of the upload-box styling remains unchanged */
.upload-box {
  width: 750px;
  height: 430px;
  border: 2px solid #007bff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  /* background-color: #f9f9f9; */
}

.upload-text {
  font-size: 16px;
  color: #6c757d;
  margin-bottom: 20px;
}

.upload-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-btn:hover {
  background-color: #0056b3;
}

.upload-btn:active {
  background-color: #004494;
}

.upload-icon {
  margin-right: 8px;
  font-size: 20px;
}

.uploadIcon {
  cursor: pointer;
  width: 30px; 
  height: 50px; 
}




  /* ////////////////////////////////////////// */

/* ImageUpload.css */
/* Root Variables */
:root {
  --color-primary: #007bff; /* Soft blue */
  --color-secondary: #28a745; /* Teal */
  --color-background: #ffffff; /* White */
  --color-text: #333333; /* Dark gray */
  --color-success: #27ae60; /* Green */
  --color-error: #e74c3c; /* Red */
  --color-border: #dddddd; /* Light gray */
  --color-overlay: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  --font-family: 'Inter', sans-serif;
  --font-size-base: 16px;
  --font-size-large: 18px;
  --font-size-small: 14px;
}

body {
  font-family: var(--font-family);
  background-color: #f0f2f5; /* Light gray background for the entire page */
  margin: 0;
  padding: 0;
}

/* Content Section */
.content-section {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  position: relative;
}

/* Container for all progress items */
.upload-progress-face {
  position: fixed; /* Keeps it in the viewport */
  top: 20px;
  right: 20px;
  width: 350px; /* Adjusted width to accommodate close button */
  max-height: 80vh; /* Prevent overflow */
  overflow-y: auto; /* Scroll if content exceeds max-height */
  background-color: var(--color-background);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
  z-index: 1000; /* Ensure it stays on top */
  transition: all 0.3s ease;
}

/* Header for the upload progress */
.upload-progress-face-header {
  font-size: var(--font-size-large);
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--color-text);
  text-align: center;
}

/* Individual progress item */
.progress-item-face {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.progress-item-face:hover {
  background-color: #f0f4f7;
  transform: translateY(-2px);
}

/* Icon styling */
.progress-icon-face {
  margin-right: 12px;
  flex-shrink: 0;
}

.progress-icon-face.success {
  color: var(--color-success);
}

.progress-icon-face.error {
  color: var(--color-error);
}

.progress-icon-face.uploading {
  color: var(--color-primary);
}

/* Filename and Progress Info */
.progress-info {
  flex: 1;
}

.filename {
  display: block;
  font-size: var(--font-size-base);
  font-weight: 500;
  color: var(--color-text);
  margin-bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress-bar-face {
  width: 100%;
  height: 8px;
  background-color: var(--color-border);
  border-radius: 4px;
  overflow: hidden;
}

.progress-fill-face {
  height: 100%;
  background-color: var(--color-primary);
  width: 0%;
  transition: width 0.4s ease;
}

/* percentage-face Text */
.percentage-face {
  margin-left: 12px;
  font-size: var(--font-size-small);
  color: var(--color-text);
  flex-shrink: 0;
}

/* Spinner animation */
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.spinner {
  animation: spin 1s linear infinite;
}

/* Upload summary message */
.upload-summary {
  margin-top: 20px;
  padding: 15px;
  background-color: #e8f5e9;
  border-radius: 8px;
  color: var(--color-success);
  font-size: var(--font-size-base);
  text-align: center;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* Close button styling */
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 22px;
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: var(--color-text);
}

/* Failed uploads section within the progress bar */
.failed-uploads {
  margin-top: 25px;
  padding-top: 15px;
  border-top: 1px solid var(--color-border);
}

.failed-uploads h3 {
  font-size: var(--font-size-base);
  color: var(--color-error);
  margin-bottom: 10px;
  text-align: center;
}

.failed-uploads ul {
  list-style-type: disc;
  padding-left: 30px;
}

.failed-uploads li {
  font-size: var(--font-size-small);
  color: var(--color-text);
  margin-bottom: 5px;
}

/* Upload box styling */
.upload-box {
  width: 100%;
  max-width: 800px;
  height: 400px;
  border: 1px solid var(--color-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: #ffffff;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
}

.upload-box:hover {
  /* background-color: #f9fafe; */
  background-color: #f9fefb;
  border-color: var(--color-secondary);
}

/* .uploadIcon {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
} */

.upload-text {
  font-size: var(--font-size-large);
  color: var(--color-text);
  margin-bottom: 25px;
  text-align: center;
}

.upload-btn {
  background-color: var(--color-primary);
  color: #ffffff;
  border: none;
  padding: 14px 24px;
  border-radius: 8px;
  font-size: var(--font-size-base);
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.upload-btn:hover {
  background-color: var(--color-secondary);
}

.upload-btn:active {
  transform: scale(0.98);
}

.upload-icon {
  margin-right: 8px;
  font-size: 20px;
}

/* Failed uploads section below the upload box */
.failed-uploads-section {
  margin-top: 25px;
  max-width: 500px;
  width: 100%;
}

.failed-uploads-section h3 {
  font-size: var(--font-size-base);
  color: var(--color-error);
  margin-bottom: 10px;
  text-align: center;
}

.failed-uploads-section ul {
  list-style-type: disc;
  padding-left: 30px;
}

.failed-uploads-section li {
  font-size: var(--font-size-small);
  color: var(--color-text);
  margin-bottom: 5px;
}

/* Upload Overlay Styling */
.upload-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-overlay); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Below the progress bar */
  animation: fadeIn 0.3s ease;
}

.upload-overlay-content {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.3s ease;
}

.upload-overlay-content .spinner {
  margin-bottom: 20px;
  color: var(--color-primary);
}

.upload-overlay-content p {
  font-size: var(--font-size-large);
  color: var(--color-text);
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideDown {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}


/* Define the transition for smooth color change */
.uploadIcon {
  width: 30px;
  height: 60px;
  color: var(--color-primary);
  transition: fill 0.3s ease, stroke 0.3s ease; 
}

.upload-box:hover .upload-btn{
  background-color: var(--color-secondary);
}

.upload-box:hover .uploadIcon {
  color: var(--color-secondary);
}
