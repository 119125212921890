* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }
  
  .image-viewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
  }
  
  .loader {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Light gray */
    border-radius: 50%;
    border-top: 4px solid #ffffff; /* White */
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2000; /* Ensure it's above all content */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .image-container {
    display: flex;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .image-container::-webkit-scrollbar {
    display: none;
  }
  
  .image-item {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-item img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .gallary-options {
    width: 100%;
    height: 65px;
    background-color: rgba(33, 33, 33, 0.33);
    position: fixed;
    top: 0px;
    z-index: 1;
  }
  
  .image-gallery-icons {
    position: absolute;
    top: 20px;
    right: 60px;
    display: flex;
    gap: 10px;
  }
  
  .image-gallery-icons button {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .image-gallery-icons button:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .image-gallery-icons svg {
    width: 24px;
    height: 24px;
    fill: white;
  }
  
  .image-gallery-icons .close-button svg {
    fill: #0174f7;
  }
  
  .image-gallery-icons .download-button svg {
    fill: #fff;
  }
  
  .image-gallery-icons .favorite-button.active svg {
    fill: #c21c13;
  }
  
  .controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .controls button {
    background-color: rgba(65, 65, 65, 0.8);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .controls button:hover {
    background-color: rgb(17, 16, 16);
  }
  
  .thumbnail-container {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }
  
  .thumbnail-container::-webkit-scrollbar {
    display: none;
  }
  
  .thumbnail-container img {
    width: 70px;
    height: auto;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .thumbnail-container img.active {
    border: 2px solid white;
    transform: scale(1.1);
  }
  
  .thumbnail-container img:hover {
    transform: scale(1.2);
  }
  
  .controls button {
    background-color: rgba(65, 65, 65, 0.8);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .controls button:hover {
    background-color: rgb(17, 16, 16);
  }
  
  .controls button:hover svg {
    color: white; /* Change the icon color to white on hover */
    fill: white; /* Ensure the fill color of the icon is also white */
  }
  
  .fa-chevron-right,
  .fa-chevron-left {
    color: #fff;
    font-size: 20px;
    transition: color 0.3s ease; /* Smooth transition for color change */
  }
  
  
  
  
  .thumbnail-container {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    /* border: 2px solid rgb(204, 202, 202);  */
    background-color: rgba(0, 0, 0, 0.3); /* Add for debug */
  }
  
  
  .thumbnail-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  }
  
  .thumbnail-container::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  
  .thumbnail {
    flex: 0 0 auto;
    width: 100px; /* Adjust as needed */
    height: auto;
    margin-right: 10px; /* Adjust as needed */
  }
  
  
  
  .thumbnail-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 10px 0;
  }
  
  .thumbnail-container::-webkit-scrollbar {
    display: none;
  }
  
  .thumbnail {
    flex: 0 0 auto;
    width: 80px; /* Adjust as needed */
    height: 60px; /* Adjust as needed */
    margin-right: 10px;
    object-fit: cover;
    border: 2px solid transparent;
    transition: border-color 0.3s ease;
  }
  
  .thumbnail.active {
    border-color: #007bff; /* Highlight color for the active thumbnail */
  }
  
  
  
  
  .thumbnail {
    flex: 0 0 auto;
    width: 80px; /* Adjust as needed */
    height: 60px; /* Adjust as needed */
    margin-right: 10px;
    object-fit: cover;
  }
  
  
  .thumbnail-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    padding: 0 calc(50% - 40px); /* Adjust based on half your container width minus half a thumbnail width */
    width: 100%;
  }
  
  .thumbnail-wrapper {
    display: flex;
    min-width: min-content; /* Ensures the container can grow to fit all thumbnails */
  }
  
  
  .thumbnail-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    padding: 0 calc(50% - 40px); /* Adjust based on half your container width minus half a thumbnail width */
    width: 100%;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
  }
  
  .thumbnail-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
  
  .thumbnail-wrapper {
    display: flex;
    min-width: min-content;
  }
  
  .thumbnail {
    flex: 0 0 auto;
    width: 80px; /* Adjust as needed */
    height: 60px; /* Adjust as needed */
    margin-right: 10px;
    object-fit: cover;
    transition: all 0.3s ease;
  }
  
  .thumbnail.active {
    border: 2px solid #007bff; /* Highlight color for the active thumbnail */
    transform: scale(1.1);
  }
  
  
  .favorite-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .favorite-button .heart-icon {
    width: 26px;
    height: 26px;
    stroke-width: 2px;
    stroke: white;
    /* fill: none;  */
  }
  
  .favorite-button.active .heart-icon {
    stroke: red; /* Change the stroke color to red when active */
  }