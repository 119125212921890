.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.backD {
  background-color: #F8F7F7;
  width: 700px;
  height: 420px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: -5px -19px 19px -3px rgba(0, 0, 0, 0.1);
  position: relative;
}

.backDContent {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  justify-content: center;

}

.ProfilePhoto {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.Contacts {
  text-align: left;
  padding-top: 30px;
}

.Contacts ul li {
  list-style: none;
  color: #666464;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0px;
  padding-top: 25px;
}

.profilePic {
  /* background-color: #6b1d1d; */
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profilePic img {
  width: 110px;
  height: 110px;
  object-fit: cover;
  border-radius: 50%;
}

.Logout {
  background-color: #6b1d1d;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 10px 50px;
  color: #F8F7F7;
  border-radius: 10px;
  margin-top: 70px;
  cursor: pointer;
}

.ProfilePhoto p {
  padding-top: 8px;
}

.edit {
  position: absolute;
  top: 28px;
  right: 24px;
  cursor: pointer;
}

.ProfilePhoto ul {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 15px;
  padding-top: 15px;
}

.ProfilePhoto ul li {
  list-style: none;
}

.ProfilePhoto ul li a {
  text-decoration: none;
  color: #666464;
}

.profileIcon {
  width: 20px;
  height: 20px;
}