/* Reset margin, padding, and set box-sizing globally */
:global(*) {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Optional: Global Scrollbar Styling */
/*
:global(body) {
  scrollbar-width: thin; 
  scrollbar-color: #c1c1c1 transparent;
}

:global(body::-webkit-scrollbar) {
  width: 8px;
}

:global(body::-webkit-scrollbar-track) {
  background: transparent;
}

:global(body::-webkit-scrollbar-thumb) {
  background-color: #c1c1c1;
  border-radius: 4px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

:global(body::-webkit-scrollbar-thumb:hover) {
  background-color: #a8a8a8;
}
*/

.guests_container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  max-height: 500px;
  margin: 20px auto;
  overflow-y: scroll;
  
  /* Scrollbar Styling for Firefox */
  scrollbar-width: thin;
  scrollbar-color: #c1c1c1a8 transparent;
}

/* WebKit Scrollbar Styling for guests_container */
.guests_container::-webkit-scrollbar {
  width: 8px;
}

.guests_container::-webkit-scrollbar-track {
  background: transparent; 
}

.guests_container::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 4px;
  border: 2px solid transparent; /* Creates padding around thumb */
  background-clip: padding-box; /* Ensures border doesn't overlap thumb */
}

.guests_container::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}

.header {
  text-align: center;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  justify-content: center;
  align-items: flex-start;
}

.header h2 {
  font-size: 15px;
  padding-bottom: 20px;
  /* color: #007BFF; */
}

.guests_list_container {
  padding: 10px;
}

.total_guests {
  font-size: 1.1em;
  margin-bottom: 15px;
  text-align: center;
}

.guest_items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 15px;
}

.guest_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  padding: 15px;
  border-radius: 8px;
  /* background-color: #f9f9f9; */
}

.guest_item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.guest_item img {
  width: 71px;
  height: 71px;
  border-radius: 50% !important; 
  object-fit: cover;
  /* border: 3px solid #007BFF;  */
  transition: transform 0.3s;
}

.guest_item img:hover {
  transform: scale(1.1);
}

.guest_info {
  margin-top: 25px;
  text-align: center;
}

.guest_info h4 {
  margin-bottom: 5px;
  font-size: 1.1em;
  color: #333;
}

.guest_info p {
  margin: 2px 0;
  font-size: 0.9em;
  color: #555;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal_content {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 1000px;
  max-height: 90vh;
  /* overflow-y: auto;  */
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  
  /* Scrollbar Styling for Firefox */
  /* scrollbar-width: thin;
  scrollbar-color: #c1c1c1 transparent; */
}

/* WebKit Scrollbar Styling for modal_content */
.modal_content::-webkit-scrollbar {
  width: 8px;
}

.modal_content::-webkit-scrollbar-track {
  background: transparent; 
}

.modal_content::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 4px;
  border: 2px solid transparent; /* Creates padding around thumb */
  background-clip: padding-box; /* Ensures border doesn't overlap thumb */
}

.modal_content::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}

.close_button {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 1.5em;
  color: #555;
  cursor: pointer;
}

.close_button:hover {
  color: #000;
}

.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  /* background-color: #fff; */
  padding: 20px;
  z-index: 10;
  border-bottom: 1px solid #ddd;
}

.modal_header h3 {
  font-size: 20px;
  color: #424242;
}

.whatsapp_section {
  display: flex;
  align-items: center;
}

.whatsapp_icon {
  font-size: 1.5em;
  color: #25D366; /* WhatsApp Green */
  margin-right: 10px;
}

.whatsapp_number {
  font-size: 1em;
  color: #333;
  margin-right: 15px;
}

.send_button {
  background-color: #25D366; /* WhatsApp Green */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, transform 0.3s;
  display: flex;
  align-items: center;
}

.send_button:hover {
  background-color: #1ebe5d;
  transform: translateY(-2px);
}

.send_button:active {
  transform: translateY(0);
}

.send_button svg {
  margin-right: 8px;
}


.images_container_whole{
  /* height: auto; */
  overflow-y: auto;
}

.modal_images_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Create a responsive grid */
  gap: 10px; 
  padding: 10px;
  justify-items: center; 
  /* overflow-y: scroll; */
  /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
  /* gap: 10px; */
  /* padding: 20px; */
  max-height: 500px;
  overflow-y: scroll;
  scrollbar-width: none;

}
.image_contents{
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
}
.modal_image {
  /* width: 100%; 
  max-width: 250px; 
  height: auto;  */
  /* max-width: 180px;
  max-height: 150px;
  border-radius: 8px;
  object-fit:cover;
  transition: transform 0.3s;
  cursor: pointer; */

  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.modal_image:hover {
  transform: scale(1.05);
}

/* Sticky Scroll Buttons Positioned at the Bottom */
.scroll_buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  position: sticky;
  bottom: 0;
  background-color: #fff; /* Ensure buttons don't overlap images */
  border-top: 1px solid #ddd;
  z-index: 10;
}

.scroll_top,
.scroll_bottom {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.9em;
}

.scroll_top:hover,
.scroll_bottom:hover {
  background-color: #0056b3;
}


/* Optional: Pagination Styles (if implementing pagination) */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
}

.pagination button {
    background-color: #007BFF;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #0056b3;
}

.pagination span {
    font-size: 1em;
    color: #333;
}

/* Lightbox Styles */
.lightbox_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100; /* Higher than modal */
}

.lightbox_content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.lightbox_content img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.lightbox_close {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #fff;
  color: #333;
  border: none;
  border-radius: 50%;
  font-size: 1.5em;
  width: 30px;
  height: 30px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s;
}

.lightbox_close:hover {
  background-color: #f0f0f0;
}
