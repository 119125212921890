* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
 font-family: "Cabin", sans-serif;
  background-color: #fff;
  overflow-x: hidden;
}

.album_Head {
  display: flex;
  gap: 0px;
  margin-top: 20px;
  position: relative; 
}

.album_web img {
  width: 630px;
  height: auto; 
}

.album_mob img {
  position: absolute;
 top: 20px;
  right: 20px;
  max-width: 50%;
  height: 300px;
  z-index: 1;
}
.album_layout{
  margin-top: 100px;
  width: 770px;
  height: 400px;
  background: #F5F5F5;
  margin-bottom: 100px;
}
.head_layout{
  display: flex;
  gap: 40px;
  padding: 25px;
  color: #b9b9b9;
  border-radius: 10px;
}
.head_layout p:first-child{
  color: #000;
  font-weight: 500;
}
.horizontal_line{
  border-bottom: 1px solid rgb(175, 175, 175); 
  width: 95%; 
  margin: 0 auto;

}

.gallary_layout{
  padding-top: 20px;
  max-width: 940px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}
.gallary_layout div{
  border: 2px solid rgb(225, 225, 225);
  background-color: rgba(212, 212, 212, 0.5);
  padding: 1em;
  color: #adadad;
}
.one {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}
.two {
  grid-column: 3;
  grid-row: 1 / 3;
}
.three {
  grid-column: 2;
  grid-row: 3 / 5;
}
.four {
  grid-column: 1;
  grid-row: 3 / 5;
}
.five {
  grid-column: 3;
  grid-row: 5;
}
.six {
  grid-column: 3;
  grid-row: 4;
}
