* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  font-family: 'Lato', sans-serif;
  overflow-x: hidden;
}

.homie {
  color: #fff;
  height: 100vh;
  /* background-image: url('../../public/img/marcos-paulo-prado-QYVCzK-bnYU-unsplash.jpg'); */
  background-size: cover;
}

.hero_page {
  width: 100%;
  background-color: #161616;
  height: 500vh;
}

.hero_section {
  position: relative;
  z-index: 5;
  background-position: bottom center;
  height: 800px;
}

.shape {
  position: absolute;
}

.hero_page section {
  padding-top: 30px;
}

@keyframes animation1 {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes animation1 {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes video {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
  }
}

@-webkit-keyframes video {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
  }
}

.shape1 {
  width: 75px;
  height: 75px;
  background: -webkit-linear-gradient(#005bed 0%, #0bb2fc 100%);
  background: -o-linear-gradient(#005bed 0%, #0bb2fc 100%);
  background: linear-gradient(#005bed 0%, #0bb2fc 100%);
  border-radius: 50%;
  left: 168px;
  top: 12%;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}

.shape2 {
  width: 39px;
  height: 39px;
  background: -webkit-linear-gradient(#005bed 0%, #0bb2fc 100%);
  background: -o-linear-gradient(#005bed 0%, #0bb2fc 100%);
  background: linear-gradient(#005bed 0%, #0bb2fc 100%);
  left: 150px;
  bottom: 40px;
  border-radius: 50%;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}

.shape3 {
  width: 19px;
  height: 19px;
  background: -webkit-linear-gradient(rgba(54, 28, 193, 0.5) 0%, rgba(46, 130, 239, 0.5) 100%);
  background: -o-linear-gradient(rgba(54, 28, 193, 0.5) 0%, rgba(46, 130, 239, 0.5) 100%);
  background: linear-gradient(rgba(54, 28, 193, 0.5) 0%, rgba(46, 130, 239, 0.5) 100%);
  bottom: 25%;
  left: 26%;
  border-radius: 50%;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}

.shape4 {
  background-color: #0bb2fc;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  top: 65px;
  left: 36%;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}

.shape5 {
  width: 19px;
  height: 19px;
  background-color: #055bed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20%;
  border-radius: 50%;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}

.shape6 {
  width: 14px;
  height: 14px;
  background-color: #0bb2fc;
  border-radius: 50%;
  left: 45%;
  bottom: 70px;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;

}

.hero_page section {
  position: relative;
}

.content {
  width: 100%;
  display: grid;
  grid-template-columns: 720px 620px;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: absolute;
  height: 600px;
  /* top: 200px;
  left: 100px; */
  /* gap: 100px; */
}

.heroText {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  margin: 0 auto;
  padding-left: 100px;
  font-weight: 600;
}

.heroText h2 {
  width: 540px;
  animation: slideInAnimation1 1s ease-out forwards;
}

.heroText h2:first-child {
  color: #055bed;
}

.heroText h2 span {
  color: #fff;
}

.heroImg {
  margin: 0 auto;
  padding-top: 100px;
  animation: slideInAnimation2 1s ease-out forwards;
}

.heroImg img {
  height: 450px;
}

.signupbox {
  width: 100%;
  cursor: pointer;
  padding-top: 30px;
}

.signBtn {
  color: #fff;
  padding: 10px 20px;
  background-color: #0bb2fc;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border: 2px solid #0bb2fc;
  /* transition: all 0.4s ease-out 0s; */
  padding: 0 25px;
  font-size: 16px;
  height: 55px;
  line-height: 51px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-weight: 300;
  animation: slideInAnimation1 1s ease-out forwards;
}
.signBtn li {
  list-style: none;
}
.signBtn li a{
  text-decoration: none;
  color: #fff;
}
/* .slideIn {
  animation: slideInAnimation 1s ease-out forwards;
} */

@keyframes slideInAnimation1 {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInAnimation2 {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@media screen and (max-width: 480px){
  .content {
    display: block;
    overflow-x: hidden;
    padding-top: 48px;
  }
  .heroText {
    display: inline-block;
    font-size: 22px;
    font-weight: 500;
    padding: 0px;
    text-align: center;
  }
  .heroText h2 {
    width: 400px;
    }
    .shape1{
      left: 278px;
      top: 20%;
    }
    .shape2{
      left: 150px;
      bottom: 40px;
    }
    .shape3{
      bottom: 25%;
      left: 26%;
    }
    .shape4{
      top: -16px;
      left: 7%;
    }
    .shape5{
      left: 50%;
      bottom: 20%;
    }
    .shape6{
      left: 45%;
      bottom: 70px;
    }
    .heroImg {
      padding-left: 10px;
     overflow-y: hidden; 
    }
  
}





.brief {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.brief .title {
  font-size: 35px;
  font-weight: 600;
}

.brief p {
  width: 700px;
  text-align: center;
  text-wrap: pretty;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  color: #b4b4b4;
}

.projection {
  position: relative;
  width: 100%;
  height: 700px;
  padding-top: 65px;
  background-color: #0b0b0b;
}

.about_image {
  text-align: center;
  top: 100px;
  left: 225px;
  z-index: 10;
}

.about_image img {
  z-index: 10;
}

.aboutShape {
  position: absolute;
  background: #005bed;
  border-radius: 50%;
  width: 450px;
  height: 450px;
  top: 40px;
  left: 198px;
  /* z-index: -1; */
}

.aboutShape.animate {
  animation: slideInAnimation1 1s ease-out forwards;
}

/* Add a new keyframe animation */
@keyframes appAnimation {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Define the class to trigger the animation */
.app.animate {
  animation: appAnimation 1s ease-out forwards;
}


@keyframes newOne1 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.features {
  width: 100%;
  height: 620px;
  background-color: #161616;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 800px 750px;
  margin: 0 auto;
}
/* .features .images{
  animation: slideInAnimation1 1s ease-out forwards;
} */
/* .imagesOne{
   animation: slideInAnimation1 1s ease-out forwards;
} */
.featuresOne {
  width: 100%;
  height: 620px;
  background-color: #161616;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 750px 500px;
  margin: 0 auto;
}

.images {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  animation: slideInAnimation1 1s ease-out forwards;
}

.images img {
  max-width: 295px;
  margin: 0 auto;
  z-index: 10;
}

.images::before {
  content: "";
  position: absolute;
  top: 41px;
  left: 175px;
  width: 150px;
  height: 150px;
  background-color: #005bed;
  border-radius: 50%;
  z-index: 1;
}

.description {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  text-wrap: balance;
  height: 500px;

}

.description.animate {
  animation: slideInAnimation1 1s ease-out forwards;
}
.featuresOne .description.animate {
  animation: slideInAnimation1 1s ease-out forwards;
}
.featuresTwo .description.animate {
  animation: slideInAnimation1 1s ease-out forwards;
}

.description h2 {
  font-size: 35px;
  padding-bottom: 14px;
}

.description h2,
.description p {
  text-wrap: balance;
}

.description p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #b4b4b4;
  margin: 0px;
}

.imagesOne::before {
  content: "";
  position: absolute;
  top: 27px;
  /* left: 175px; */
  right: 300px;
  width: 150px;
  height: 150px;
  background-color: #005bed;
  border-radius: 50%;
  z-index: 1;
}

.imagesOne {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}
.imagesOne.animate {
  animation: slideInAnimation1 1s ease-out forwards;
}
.imagesOne img {
  max-width: 295px;
  margin: 0 auto;
  z-index: 10;
}

.footer_area {
  position: relative;
  z-index: 5;
}

.footer_area .footer_shape {
  position: absolute;
}

.footer_area .footerShape1 {
  background: -webkit-linear-gradient(#005bed 0%, #0bb2fc 100%);
  background: -o-linear-gradient(#005bed 0%, #0bb2fc 100%);
  background: linear-gradient(#005bed 0%, #0bb2fc 100%);
  width: 75px;
  height: 75px;
  border-radius: 50%;
  left: 70px;
  bottom: 195px;
  opacity: 0.55;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}

.footer_area .footerShape2 {
  background: -webkit-linear-gradient(#33c8c1 0%, #119bd2 100%);
  background: -o-linear-gradient(#33c8c1 0%, #119bd2 100%);
  background: linear-gradient(#33c8c1 0%, #119bd2 100%);
  width: 39px;
  height: 39px;
  border-radius: 50%;
  bottom: -60px;
  left: 25%;
  opacity: 0.55;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}

.footer_area .footerShape3 {
  background: -webkit-linear-gradient(#361cc1 0%, #2e82ef 100%);
  background: -o-linear-gradient(#361cc1 0%, #2e82ef 100%);
  background: linear-gradient(#361cc1 0%, #2e82ef 100%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 50%;
  top: -250px;
  opacity: 0.55;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}

.footer_area .footerShape4 {
  background-color: #0bb2fc;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: 50%;
  bottom: -65px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0.55;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}

.footer_area .footerShape5 {
  background-color: #0bb2fc;
  right: 37%;
  top: -300px;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  opacity: 0.55;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}

.footer_area .footerShape6 {
  background-color: #005bed;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: -125px;
  right: 15%;
  opacity: 0.55;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}

.footer_area .footerShape7 {
  background-color: #0bb2fc;
  width: 64px;
  height: 64px;
  bottom: -72px;
  right: 10%;
  opacity: 0.55;
  border-radius: 50%;
  -webkit-animation: animation1 2s linear infinite;
  -moz-animation: animation1 2s linear infinite;
  -o-animation: animation1 2s linear infinite;
  animation: animation1 2s linear infinite;
}

.footer {
  position: relative;
}

.footerContainer {
  margin: 0px 210px;
  position: relative;
  height: 350px;
}

.footerDetail {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  z-index: 500;
  gap: 53px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: fit-content;
}

.brand,
.product,
.company,
.reach {
  background-color: transparent;
  text-align: left;
}

.brand ul,
.product ul,
.company ul,
.reach ul {
  text-align: left;
}

.brand ul li a,
.product ul li a,
.company ul li a,
.reach ul li a {
  color: #c7c7c7;
  font-size: 16px;
  font-weight: 500;
}

.brand ul li,
.product ul li,
.company ul li,
.reach ul li {
  padding-top: 10px;
}

.logo li {
  list-style: none;
}

.logo li a {
  text-decoration: none;
}

.logo img {
  max-width: 150px;
}

.links li {
  list-style: none;
}

.links li a {
  text-decoration: none;
}

.brand p {
  /* color: #747E88; */
  color: #c7c7c7;
  font-size: 15px;
}

.footer {
  /* background-color: #0b0b0b; */
  background-color: #2a2a2a;
  height: 520px;
  /* color: #464646; */
  color: #e7e7e7;
}

.imagesTwo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

.imagesTwo.animate {
  animation: slideInAnimation1 1s ease-out forwards;
}

.imagesTwo img {
  max-width: 295px;
  margin: 0 auto;
  z-index: 10;
}

.imagesTwo::before {
  content: "";
  position: absolute;
  top: 44px;
  left: 175px;
  width: 150px;
  height: 150px;
  background-color: #005bed;
  border-radius: 50%;
  z-index: 1;
}

.mob_footer{
  display: none;
}
.socialicons{
  padding-top: 0px;
}
.socialicons ul{
  display: flex;
  padding-top: 15px;
  gap: 20px;
}
.socialicons ul li {
  list-style: none;
}
.socialicons ul li a{
  text-decoration: none;
}
.icons{
  width: 23px;
  height:  23px;
  color: #b9b7b7;
}
.borderLine{
  border: 1px solid #ebe9e9;
  margin: 92px 190px 0px 190px;
  
}
.footerBottom{
  margin: 0px 195px;
display: flex;
justify-content: space-between;
align-items: center;
}
.footerBottom span{
  color: #0bb2fc;
}

@media screen and (max-width: 480px) {

  .content{
    display: block;
    height: 905px;
    overflow: hidden;
    padding-top: 48px; 
  }

  .hero_page{
    width: 450px;
  }
  .heroText {
    display: inline-block;
    font-size: 24px;
    font-weight: 500;
    padding: 0px;
    text-align: center;
  }

  .heroText h2 {
    line-height: 58.5px;
    width: 440px;
  }

  .shape1 {
    left: 305px;
    top: 36%;
  }

  .shape2 {
    left: 150px;
    bottom: 40px;
  }

  .shape3 {
    bottom: 25%;
    left: 26%;
  }

  .shape4 {
    top: -16px;
    left: 7%;
  }

  .shape5 {
    left: 50%;
    bottom: 20%;
  }

  .shape6 {
    left: 45%;
    bottom: 70px;
  }

  .heroImg {
    padding-left: 10px;
    overflow-y: hidden;
  }

  .brief {
    display: inline-block;
    padding-top: 171px;
    /* align-items: center;
      justify-content: center; */
    /* flex-direction: column; */
  }

  .brief .title {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    padding-top: 108px;
  }

  .brief p {
    /* width: 420px; */
    width: 100%;
    padding: 10px 20px 0px 20px;
  }

  .projection {
    position: relative;
    /* overflow-y: hidden; */
    /* width: 450px; */
    width: 100%;
    height: 470px;
    padding-top: 65px;
    background-color: #0b0b0b;
  }

  .about_image {
    position: absolute;
    display: inline-block;
    text-align: center;
    left: 10px;
  }

  .about_image img {
    max-width: 25rem;
    border-radius: 2px;
    padding: 0px;
  }

.aboutShape {
  position: absolute;
  background: #005bed;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  top: -30px;
  left: 24px;
  /* z-index: -1; */
}

  .features {
    width: 100%;
    height: 800px;
    background-color: #161616;
    display: block;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .featuresOne {
    width: 100%;
    height: 500px;
    background-color: #161616;
    display: block;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    margin: 0 auto;
    padding-bottom: 100px;
  }
  .imagesOne::before {
    content: "";
    position: absolute;
    top: 93px;
    right: 270px;
    width: 150px;
    height: 150px;
    background-color: #005bed;
    border-radius: 50%;
    z-index: 1;
  }
  .imagesOne{
    padding-top: 100px;
  }
  .imagesOne.animate {
    animation: slideInAnimation1 1s ease-out forwards;
  }

  .images {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
  }
  .images.animate {
    animation: slideInAnimation1 1s ease-out forwards;
  }
  .images img {
    max-width: 295px;
    margin: 0 auto;
    z-index: 10;
  }

  .images::before {
    content: "";
    position: absolute;
    top: 51px;
    left: 27px;
    width: 150px;
    height: 150px;
    background-color: #005bed;
    border-radius: 50%;
    z-index: 1;
  }
  .featuresOne .description {
    display: inline-block;
    justify-content: center;
    text-wrap: balance;
    height: 150px;
    text-align: center;
  }
  .description {
    display: inline-block;
    justify-content: center;
    text-wrap: balance;
    height: 500px;
    text-align: center;
  }

  .description h2 {
    font-size: 35px;
    padding-bottom: 14px;
  }

  .description h2,
  .description p {
    text-wrap: balance;
  }

  .description p {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #b4b4b4;
    margin: 0px;
  }
.featuresTwo{
  width: 100%;
  height: fit-content;
  margin-top: 300px;
  display: block;
}
.imagesTwo::before {
  content: "";
  position: absolute;
  top: 41px;
  left: 31px;
  width: 150px;
  height: 150px;
  background-color: #005bed;
  border-radius: 50%;
  z-index: 1;
}
.featuresTwo .description{
  height: 280px;
}
/* footer{
  display: none;
} */
.footer{
  display: none;
}
.mob_footer{
  display: block;
    /* background-color: #0b0b0b; */
    background-color: #2a2a2a;
    height: fit-content;
    color: #e7e7e7;
}
.mob_brand{
  padding: 20px;
}
.mob_logo img{
  max-width: 200px;
}
.mob_logo p{
  /* width: 450px; */
  width: 100%;
}
.mob_logo li a{
  text-decoration: none;
}
.mob_logo li {
  list-style: none;
}
.socialicons{
  padding-top: 20px;
}
.socialicons ul{
  display: flex;
  padding-top: 15px;
  gap: 20px;
}
.socialicons ul li {
  list-style: none;
}
.socialicons ul li a{
  text-decoration: none;
}
.icons{
  width: 25px;
  height: 25px;
  color: #b9b7b7;
}

.firstRow{
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 240px;
  padding: 20px;
}

.product
.company{
height: 400px;
}
.mob_brand p{
  font-size: 18px;
}
.signupbox {
  padding-top: 52px;
}

.borderLine{
  border: 1px solid #ebe9e9;
  margin: 92px 30px 0px 30px;
  
}
.footerBottom{
  margin: 0px 40px;
display: flex;
justify-content: space-between;
align-items: center;
}
.footerBottom p{
  font-size: 12px; 
}
.footerBottom 
.footerBottom span{ 
  color: #0bb2fc;
}
}
