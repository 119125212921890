/* import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css'; */


* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* overflow: hidden; */
}

body {
 font-family: "Cabin", sans-serif;
  background-color: #fff;
  /* overflow-x: hidden; */
}

/* Add styles for modal and overlay */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingModal {
  background-color: white; 
  padding: 20px;
  border-radius: 5px;
}

.loadingOverlay {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}
.spaceout{
  height: 0px;
}

.galleryModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Adjust the z-index value as needed */
}

.galleryModalContent {
  position: relative;
  outline: none;
  width: 80vw;
  height: 80vh;
  overflow: hidden;
  z-index: 1001; /* Adjust the z-index value to be higher than the modal overlay */
}



/* .DeleteButton {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 100%;
  background-color: rgb(252, 252, 252);
  color: rgb(0, 0, 0);
  cursor: pointer;
  padding: 0px 7px;
  font-size: 17px;
} */

/* .GalleryTile {
  position: relative;
} */

.folderPortion{
  /* overflow: scroll; */
  /* background-color: #0575C6; */
}
.folderOuter {
  padding-top: 20px;
}

/* .foldersStack a{
    cursor: pointer;
    margin-top: 20px;
    list-style: none;
} */
.foldersStack {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.foldersStack {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between; /* Add this line to distribute items at the top and bottom */
}

/* .foldersStack a {
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 10px;
  border: 1px solid #6B6A6A;
  width: 255px;
  height: 48px;
  list-style: none;
  color: #6B6A6A;
  font-size: 16px;
  text-decoration: none;
} */
.normalrange{
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 10px;
  border: 1px solid #6B6A6A;
  width: 255px;
  height: 48px;
  list-style: none;
  color: #6B6A6A;
  font-size: 16px;
  text-decoration: none;
}
.selectedFolder {
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 10px;
  border: 2px solid #4274F6;   
  /* border: 2px solid #0392bd; */
  width: 255px;
  height: 48px;
  list-style: none;
  color: #6B6A6A;
  font-size: 16px;
  text-decoration: none;
}

.foldersStack span {
  padding-right: 25px;
  color: #fff;
}

/* FolderPopup.module.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for the blur effect */
  z-index: 1; /* Ensure the overlay is above other content */
}


.foldersOuterStk {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap; /* Ensure no wrapping in the horizontal direction */
  overflow-x: auto; /* Enable horizontal scrolling */
  width: 818px; /* Set a fixed width */
}

.folderOuter {
  /* Add or adjust styles as needed */
  height: 100%; /* Set a fixed height */
  overflow: hidden; /* Hide any content that overflows */
}

.folderAdd {
  color: #0575C6;
  cursor: pointer;
  width: 50px;
  font-size: 12px;
  padding-top: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}




.contentHead {
  margin: 0;
  color: #7B7B7B;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding-left: 18px;
  padding-top: 0px;
}

.photoHead span {
  padding-top: 5px;
}

.photoHead p {
  padding-left: 10px;
  margin: 0px;
}

.photoHead {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  width: 824px;
  height: 67px;
  border-radius: 14px 14px 0px 0px;
  background: #E5E3E3;
}

.NumPhotos {
  color: #6B6A6A;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sectionTwo {
  /* background-color: red; */
  border-radius: 15px 15px 15px 15px;
  position: relative;
  background: #FFF;
  width: auto;
  /* height: 220px; */
  height: fit-content;
}


.photoBody {
  height: fit-content;
  /* flex-direction: column; */
}
.photoBodyBarrier{
  display: flex;
  justify-content: center;
  align-items: center;
}
.photoBody ul {
  padding: 20px 0px 20px 50px;
}

.photoBody li {
  cursor: pointer;
  margin-top: 20px;
  list-style: none;
}

.photoBody li a {
  list-style: none;
  color: #6B6A6A;
  text-decoration: none;
}

.photoBody li span {
  padding-right: 5px;
}

.UploadBox {
  padding-top: 20px;
}

.uploadborder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  text-align: center;
  vertical-align: middle;
  /* justify-content: center; */
  width: 768px;
  height: 200px;
  border-radius: 10px;
  border: 1px solid #417EDA;
}

.uploadCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.DropHere {
  color: #747474;
  text-align: center;
  font-size: 14px;
  padding: 0px;
  margin: 0px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.DropTag {
  color: #747474;
  text-align: center;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  line-height: normal;
}
.UploadingBtn {
  cursor: pointer;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 156px;
  height: 39px;
  border-radius: 10px;
  background: #256FFF;
  color: #FFF;
  font-size: 15px;
  border-color: none;
}



/* .pic{
  background-color:#afaeae ;
  width: 150px;
  height: 150px;
} */
.wide{
  width: 815px;
  /* background-color: #7B7B7B; */
}

.Gallary {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; 
  padding: 10px 30px;
}

.GalleryTile {
  position: relative;
  width: 180px; 
  height: 180px; 
  overflow: hidden;
}

.Gallary  img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
}

.DeleteButton {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 100%;
  background-color: rgb(252, 252, 252);
  color: rgb(0, 0, 0);
  cursor: pointer;
  padding: 0px 7px;
  font-size: 17px;
}
/* .viewmore{
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #256FFF;
  font-size: 15px;
  padding: 5px 10px;
  align-items: flex-end;
  color: #fff;
  width: fit-content;
  margin-top: 30px;
  position: absolute;
  right: 50%;

} */


/* YourComponent.module.css */

/* .Gallery {
  display: flex;
  flex-wrap: wrap;
}

.GalleryTile {
  width: calc(33.33% - 10px); 
  margin: 5px;
} */

.ViewMoreButton {
  /* position: absolute;
  top: 10px; 
  right: 10px;  */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 10px;  
  background-color: #e4e4e4cc;
  color: #050505;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 48px 42px 0px 670px;
}
/* .ViewMoreButton span {
  width: 20px;
  height: 20px;
} */
/* .progressBarContainer {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 2px;
  margin-top: 10px;
}

.progressBar {
  height: 10px;
  background-color: #4caf50;
  border-radius: 2px;
  transition: width 0.4s ease;
} */
.progressBar{
  width: 700px;
  /* display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0; */
}