
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
 font-family: "Cabin", sans-serif;
  background-color: #fff;
  overflow-x: hidden;
}
/* .outlets{
  overflow: scroll;
} */
.clientTitle {
  /* position: sticky;
  top: 0; */
  overflow: visible;
    margin: 2%;
    padding: 1%;
    border-radius: 15px;
    background: #F3F1F1;
    height: 55px;
    color: #555;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.topClients {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 47px;
}

.topClients span {
    padding-top: 3px;
    cursor: pointer;
}
.albumTitle{
    display: flex;
    align-items: center;
    padding-top:15px ;
    gap: 5px;
    color: #424242;
}
.albumTitle p{
    font-size: 18px;
    font-weight: 500;
}
.Horizontalinks nav {
    display: flex;
    gap: 50px;
    border-bottom: 1px solid #ccc;
    padding-top: 25px;
  }
  
  .Horizontalinks nav a {
    color: #7B7B7B;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
  }
  
  .Horizontalinks nav a.active {
    border-bottom: 2px solid #555; /* Adjust the thickness and color as needed */
    padding-bottom: 5px; /* Add space between text and underline */
    font-weight: 600;
    color: #555 !important; 
  }

  .wholePart{
    display: grid;
    grid-template-columns: 3fr 1.5fr;
    /* padding: 25px;
    gap: 30px; */
    gap: 25px;
  }              
  .leftPart{
    height: fit-content;
    /* background-color: aqua; */
    padding-top: 20px;
    min-height: 100px;
  }
  .rightPart{
    /* background-color: pink; */
    padding-top: 25px;
  }

  .rightSectionOne {
    width: auto;
    height: fit-content;
    border-radius: 15px;
    border: 1px solid #E5E3E3;
    background: linear-gradient(132deg, #FFF 0.14%, rgba(223, 223, 223, 0.00) 104.28%);
  }
  .permissions{
    margin-top: 30px;
    width: auto;
    height: fit-content;
    border-radius: 15px;
    border: 1px solid #E5E3E3;
    background: linear-gradient(132deg, #F6F4F4 0.14%, rgba(226, 226, 226, 0.00) 104.28%);
  }

  .permissions ul{
    display: flex;
    align-items: center;
    padding: 12px 0px;
    gap: 12px;
  }
  .permissions ul li{
    list-style: none;
    text-decoration: none;
  }
  .permissions ul li a{
    cursor: pointer;
  }
  .Contents {
    padding: 10px 20px 20px 20px;
}
.permissions .Contents{
  padding: 10px 10px 10px 20px;
}
.Contents p {
    color: #7B7B7B;
    font-weight: 400;
    font-size: 20px;
}

.ContentsInside p {
    font-size: 15px;
    padding-top: 13px; 
    font-weight: 400;
}

.financialsBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    width: 133px;
    font-size: 15px;
    margin-top: 24px;
    height: 32px;
    border-radius: 9px;
    cursor: pointer;
    background: #4274F6;
    /* background: #0088dd; */
}

.ContentsInside p span{
    padding-right: 8px;
}
.Share{
  padding: 10px 10px 20px 10px;
  display: flex;
  justify-content: end;
}
.ShareBtn{
  color: #fff;
  width: 111px;
  height: 36px;
  font-size: 17px;
  border-radius: 10px;
  background: #256FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ShareBtn span{
  padding: 9px 0px 0px 4px;
}
.Limit,
.Security{
  display: flex;
  justify-content: left;
  align-items: center;
  padding-top: 12px;
  gap: 10px;

}
.Limit p,
.Security p{
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.SelectionSettings .Contents{
  padding: 10px 10px 10px 20px;
  
}
.SelectionSettings{
  margin-top: 30px;
  width: auto;
  height: fit-content;
  border-radius: 15px;
  border: 1px solid #E5E3E3;
  background: linear-gradient(132deg, #F6F4F4 0.14%, rgba(226, 226, 226, 0.00) 104.28%);
}
.pen {
  width: 15px;
  height: 15px;
  cursor: pointer;
} 
.Contents span{
  font-weight: 600;
} 


/* Add this to your existing CSS file or create a new one if needed */

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupContent {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.popupContent h2 {
  margin-bottom: 10px;
}

.popupContent button {
  /* Add styles for your share button */
}

.popupContent p {
  /* Add styles for your title */
}

.popupContent span {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #666;
}

.sharex{
/* position: relative; */
top: 0;
right: 0; 
}
  /* .sharenone{
  position: absolute;
  top: 0;
  right: 0; 
  } */



/*  for information p tag on share album page button (nived)*/

.share-info {

}

.share-info p {
  font-size: 12px;
  color: #666;
}
