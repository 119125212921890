/* YourComponent.css */

.line_container {
    display: flex;
    align-items: center;
    color: #6d6d6d;
    font-weight: 300;
  }
  
  .horizontal_line {
    flex-grow: 1;
    border: 0.2px solid #6d6d6d; /* Adjust the line styling as needed */
    margin: 0 10px; /* Adjust the margin as needed to create space between lines */
  }
  
  .line_space {
    margin: 0 10px; /* Adjust the space between lines as needed */
  }
  