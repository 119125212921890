/* Reset and Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #eaeef5;
  color: #333;
} */

/* Container */
.selfie-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

/* Card */
.selfie-card {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  position: relative;
  animation: fadeIn 0.5s ease-in-out;
}

/* Fade-In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Title */
.selfie-title {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #2c3e50;
}

/* Instructions */
.selfie-instruction {
  font-size: 0.9rem;
  color: #7f8c8d;
  margin-bottom: 25px;
}

/* Webcam Section */
.webcam-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Webcam Feed */
.webcam-feed {
  border-radius: 12px;
  width: 100%;
  max-width: 300px;
  height: auto;
  object-fit: cover;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Capture Button */
.capture-button {
  position: absolute;
  bottom: -30px;
  background-color: #3498db;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.capture-button:hover {
  background-color: #2980b9;
  transform: scale(1.05);
}

.capture-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
  transform: scale(1);
}

/* Spinner */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Captured Section */
.captured-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Captured Image */
.captured-img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}

/* Action Buttons */
.action-buttons {
  display: flex;
  gap: 15px;
  justify-content: center; /* Center buttons horizontally */
  /* Removed width: 100%; to prevent stretching */
}

/* Button Styles */
.button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: #ffffff;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.retake-button {
  background-color: #f39c12;
}

.retake-button:hover {
  background-color: #e67e22;
  transform: scale(1.05);
}

.submit-button {
  background-color: #27ae60;
}

.submit-button:hover {
  background-color: #1e8449;
  transform: scale(1.05);
}

/* Error Message */
.error-message {
  color: #d8000c;
  background-color: #ffbaba;
  padding: 10px 15px;
  border-radius: 8px;
  margin-top: 15px;
  text-align: center;
  width: 100%;
  max-width: 300px;
}

/* Focus Indicators */
.capture-button:focus,
.button:focus {
  outline: 3px solid #2980b9;
  outline-offset: 2px;
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
  .selfie-card {
    padding: 25px 15px;
  }

  .selfie-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  .selfie-instruction {
    font-size: 0.85rem;
    margin-bottom: 20px;
  }

  .webcam-feed {
    max-width: 280px;
  }

  .capture-button {
    width: 50px;
    height: 50px;
    bottom: -25px;
    font-size: 1.3rem;
  }

  .button {
    font-size: 0.95rem;
    padding: 10px 18px;
  }

  .captured-img {
    max-width: 280px;
  }

  .action-buttons {
    flex-direction: column;
    gap: 10px;
    align-items: center; /* Center buttons vertically */
  }

  .button {
    width: 100%;
    max-width: 280px;
    justify-content: center;
  }
}
