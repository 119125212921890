* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
 font-family: "Cabin", sans-serif;
  background-color: #fff;
}

.containerAlbumOuter {
  width: 100%;
}

.container {
  position: relative;
  width: 100%;
  height: 75vh;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../img/june.jpg');
  background-size: cover;
  background-position: bottom;
}

.content p {
  font-size: 32px;
  color: #424242;
}

.content h2 {
  font-size: 40px;
  color: #424242;
}

.TitlesAlbum {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20px;
  height: 189px;
}

.AlbumGalleryOn {
  /* background-color: #f3e9da; */
}

.AlbumGalleryOnInside {
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.AlbumGalleryOnInside::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.AlbumGalleryLine {
  border: 1px solid #fff;
  width: 100%;
}

.AlbumGalleryTab {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 40px;
  height: 150px;
  background-color: #6d340252;
  border-radius: 16px;
}

.AlbumGalleryTab ul {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 30px;
  padding-left: 30px;
}

.AlbumGalleryTab ul li {
  list-style: none;
  cursor: pointer;
}

.AlbumGalleryTab ul li a {
  font-size: 18px;
  text-decoration: none;
  color: #1f1e1e;
  padding: 8px 16px;
  display: block;
  border-radius: 15px;
  transition: background-color 0.3s ease;
}

.AlbumGalleryTab a:hover {
  background-color: #f0f0f0;
}

.activeNavLink {
  border: 1px solid #424242;
  border-radius: 15px;
  color: white;
}

@media screen and (max-width: 480px) {
  .TitlesAlbum {
      height: 125px;
  }
  .content p {
      font-size: 25px;
  }
  .content h2 {
      font-size: 26px;
  }
  .AlbumGalleryTab {
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      height: 120px;
  }
  .AlbumGalleryTab ul {
      gap: 20px;
      padding-left: 30px;
  }
  .AlbumGalleryTab ul li a {
      font-size: 15px;
  }
}
