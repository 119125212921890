.search_bar {
    display: flex;
    align-items: center;
    /* width: 760px; */
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 5px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .search_bar input {
    border: none;
    outline: none;
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border-radius: 20px;
  }
  
  .search_bar button {
    /* background-color: #0074d9; */
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .search_bar button:hover {
    /* background-color: #0056b3; */
    background-color: #0088dd;
    /* background-color: #007bff; */
  }
  
  .search_bar span{
    /* color: #0088dd; */
    color: #007bff;
  }