* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Header.module.css */
header {
    background-color: #161616;
}

.container_header {
    width: 100%;
    padding-top: 35px;
    height: 118px;
}

.header_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 200px;
}

.logo li {
    list-style: none;
}

.logo li a {
    text-decoration: none;
}

.logo img {
    max-width: 150px;
}


.header_row nav ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 60px;
    list-style: none;
}

.header_row nav ul li a {
    text-decoration: none;
    color: #fcfcfc;
    font-size: 16px;
}

.header_row nav ul li a:hover {
    color: #005bed;
}

.header_row nav ul li:first-child a {
    color: #005bed;
}

.header_row nav ul li:first-child a:hover {
    color: #fcfcfc;
}

.header_row nav ul li:last-child a:hover {
    color: #fcfcfc;
}


@keyframes slideInAnimation {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.login {
    border: 2px solid #005bed;
    border-radius: 15px;
    padding: 5px 20px;
    font-weight: 600;
    animation: slideInAnimation 1s ease-out forwards;
}

.signup {
    border: 2px solid #0bb2fc;
    border-radius: 15px;
    font-weight: 600;
    padding: 5px 20px;
    background-color: rgb(10, 66, 236);
    animation: slideInAnimation 1s ease-out forwards;
}

/* Header.module.css */
header .sticky {
    position: fixed;
    top: 0;
    transition: .3s ease-in-out;
    width: 100%;
    z-index: 100;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.6);
    background-color: #070707;
}

.mob_menu {
    display: none;
}


@media screen and (max-width: 768px) {
    .header_row {
        margin: 0px 110px;
    }

    .header_row nav {
        display: none;
    }

    .mob_menu {
        display: none;
    }

    .mob_menuOptions {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    header {
        background-color: #161616;
        width: 450px;
    }

    .header_row {
        margin: 0px 49px;
    }

    .logo img {
        max-width: 120px;
    }

    .header_row nav {
        display: none;
    }

    .container_header {
        padding-top: 25px;
        height: 90px;
    }

    .mob_menu {
        display: contents;
    }

    .mob_menuOptions { 
        display: contents;
        height: 500px;
    }
    .mob_menuOptions ul{
        /* height: 500px; */
        overflow: hidden;
        padding: 10px 20px 20px 20px;
        border-radius: 0px 0px 10px 10px;
        background-color: #fcfcfc; 
        text-align: center;
    }
    .mob_menuOptions ul li {
        list-style: none;
        padding-top: 15px;
    }
    .mob_menuOptions ul li a{
        text-decoration: none;
        color: #070707;
    }
    .mob_menuOptions ul .login,
    .mob_menuOptions ul .signup{
       margin-top: 10px;
       padding: 10px 20px;
       cursor: pointer;
    }
    .mob_menuOptions ul .login{
        border: 2px solid #171717;
        color: #070707;
        border-radius: 15px;
        margin-top: 33px;
        font-weight: 600;
    }
    .mob_menuOptions ul .signup{
        margin-bottom: 20px;
    }
    .mob_menuOptions ul .signup a{
        color: #f7f8fa;
    }
}