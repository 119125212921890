* {
  overflow: visible;
}

.eventLabel {
  font-size: 16px;
  font-weight: 500;
  color: #424242;
  margin-bottom: 5px;
  padding-top: 14px;
}

.spacing {
  height: 30px;
}

form {
  margin: 5% auto;
  max-width: 1135px;  /* Increased width */
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding: 30px;
  border-radius: 15px; /* Softer border radius */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Softer shadow */
}

.selection {
  width: 100%;
}

.eventinfo {
  font-weight: 700;
  font-size: 24px;
  /* color: #007bff; */
  color: #666666;
  padding-bottom: 30px;
  text-align: center;
}

.userInput {
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin-top: 12px;
  outline: none;
  transition: all 0.3s ease;  /* Smooth transition */
}

.userInput:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3); /* Glow effect */
}

.dateflex {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.eventNameInput,
.dateflex > div {
  flex: 1;  /* Ensure all input fields take equal space */
}

.dateflex input {
  width: 100%;  /* Ensure full width input inside the flex container */
}

.hostInput {
  margin-top: 30px;
}

.DateTime {
  width: 100%;
}

.additional_info {
  margin-top: 20px;
  text-align: right;
}

.additional_info a {
  color: #007bff;
  text-decoration: none;
  font-weight: 600;
}

.host {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}

.inputWithSelect {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; 
}

.countrySelect {
  position: absolute;
  right: 1px; 
}

.countrySelect select {
  width: 80px;
  border: none;
  outline: none;
  color: #424242;
}

.eventformbtns {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.btnContainer {
  display: flex;
  gap: 20px;  /* Increased gap between buttons */
}

.canceleventformbtn,
.submiteventformbtn {
  padding: 14px 32px;
  color: #fff;
  background-color: #424242;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.canceleventformbtn:hover {
  background-color: #6c757d;  /* Hover effect */
}

.submiteventformbtn {
  background-color: #007bff;
}

.submiteventformbtn:hover {
  background-color: #0056b3;  /* Hover effect */
}

.phone-input {
  width: 100%;
  height: 48px;
}

@media screen and (max-width: 600px) {
  .dateflex {
      flex-direction: column;
  }
}
