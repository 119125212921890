* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* overflow-x: hidden; */
}

body {
   font-family: "Cabin", sans-serif;
    background-color: #fff;
    overflow-x: hidden;
}

.settingsContainer {
    margin: 40px 20px;
}

.Bordery {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 80vh;
    border: 1px solid #868686;
    border-radius: 10px;
    padding: 50px 50px;
}

.SettingsOptions {
    margin: 15px;
    cursor: pointer;
}

.SettingsOptions p {
    font-size: 20px;
    color: #424242;
}

.IconSettings {
    width: 25px;
    height: 25px;
    color: #6e6e6e;
}

.UnderLine {
    width: 100%;
    height: 1px;
    /* Increase height for better visibility */
    background-color: #acacac;
    margin-top: 5px;
}

.optionText {
    display: flex;
    align-items: center;
    gap: 12px;
}

.LogoutText p,
.LogoutIcon {
    color: #D71717;
}
/* .SettingsOptions:last-child .IconSettings {
    color: #D71717;
} */