* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}
.clientTitle {
    margin: 2%;
    padding: 1%;
    border-radius: 15px;
    background: #F3F1F1;
    height: 55px;
    color: #555;
    /* font-family: Cabin; */
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.topClients {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 47px;
}

.topClients span {
    padding-top: 3px;
    cursor: pointer;
}
.albumTitle{
    display: flex;
    align-items: center;
    padding-top:15px ;
    gap: 5px;
    color: #424242;
}
.albumTitle p{
    font-size: 18px;
    font-weight: 500;
}
/* .albumTitle p span {
    padding-top: 13px;
} */

.Horizontalinks nav {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 10px;
  }
  
 .Horizontalinks a {
    text-decoration: none;
    color: #333;
  }
  
.designOptions{
    margin-top: 20px;
    width: 100%;
    /* background-color: rgb(7, 131, 90); */
    height: fit-content;
    border-radius: 10px;
    border: 1px solid #c6c6c6;
}
.wrapper > div {
    /* border: 1px solid rgb(233 171 88); */
    border-radius: 5px;
    /* background-color: rgb(233 171 88 / 50%); */
    padding: 1em;
  }
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 145px);
    column-gap: 0px;
    row-gap: 0px;
    grid-auto-rows: minmax(100px, auto);
  }
.one {
    grid-column: 1 / 2;
    grid-row: 1;
    text-align: end;
  }
  .one p,
  .three p{
    font-size: 15px;
    color: #6B6A6A;
  }
  .two p,
  .four p{
    font-size: 15px;
    color: #858484C9;
  }
  .two {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
  .three {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    height: 70px;
    text-align: end;
  }
  .four {
    grid-column: 2 / 3;
    grid-row: 2/3;
    height: 90px;
  }
  /* .colors{
    display: flex;
    align-items: center;
    gap: 10px;
  } */
.colorOne,
.colorTwo,
.colorThree {
  display: flex;
  flex-direction: row;
  align-items: center; 
}
.colorOne .circle{
   margin-left: 25px; 
   background: #315F95;
}
.colorTwo .circle{
   margin-left: 8px;
   background: #87BFD7;

}
.colorThree .circle{
   margin-left: 30px;
   background: #D0EBF6;
}
  .circle {
    cursor: pointer;
    width: 10px; 
    height: 10px;
    border-radius: 50%; 
  }
  

  .uploadContainer{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    row-gap: 20px;
  }
  .uploadButton{
    /* width: 275px; */
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 45px;
    text-align: center;
    border: 1px solid #4274F6;
    border-radius: 10px;
    transition: background-color 0.3s ease;
  }
 
  /* .uploadButton {
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f0f0f0;
    transition: background-color 0.3s ease;
  } */
  .uploadButton:hover {
    background-color: #dae4f7b3;
  }
  
  .uploadButton label {
    display: block;
    width: 100%;
    height: 100%;
    color: #4e4e4e;
  }
  /* .uploadButton input {
    width: 100%;
    height: 100%;
  } */




  /* .uploadButton input{
    width: 275px;
  } */
  .FoldersToDisplay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  .FoldersToDisplay p{
    font-size: 15px;
  }
  .DesignFoldersWrapper{
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding-top: 15px;
    /* background-color: #4274F6; */
    height: fit-content;
    overflow-y: scroll;
    scrollbar-width: none; 
    /* -ms-overflow-style: none; */
    padding-bottom: 30px;
  }

  .DesignFolders{
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border: 1px solid #4274F6;
    border-radius: 6px;
    padding: 5px 22px;
  }