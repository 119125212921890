@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* overflow-x: hidden; */
}
.status{
    height: 800px;
    width: 700px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container {
    width: 239%;
    height: 256px;
    background: rgb(0 91 237 / 37%);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4.5px);
    -webkit-backdrop-filter: blur(4.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: flex;
    align-items: center;
    justify-content: center;
}

.container h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
}
.lock{
    width: 300px;
}