* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }
  
  body {
   font-family: "Cabin", sans-serif;
   background-color: #fff;
   overflow-x: hidden;
  }
  .editprofile{
    display: grid;
    grid-template-columns: 1fr 5fr ;
    height: 100vh;
  }
  .clientTitle {
    margin: 2%;
    padding: 1%;
    border-radius: 15px;
    background: #F3F1F1;

    color: #555;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.topClients {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 47px;
}
.editprofileContainer{
    margin: 10px 100px 10px 100px;
    height: 86vh;
    border: 1px solid #ababab;
    border-radius: 10px;
    padding: 30px;
    position: relative;
}
.editBtn{
    display: flex;
    position: absolute;
    top: 200px;
    gap: 5px;
    align-items: center;
    right: 30px;
    cursor: pointer;
}
.editprofileinner{
    padding: 40px;
}

/* .form-container {
    display: flex;
    flex-direction: column;
    max-width: 400px;
} */

.form-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.formBox label {
    margin-right: 10px;
    width: 120px; 
    
    color: #555;
}

.formBox input {
    padding: 8px;
    width: 300px;
    margin-top: 5px;
    border: 1px solid #a7a7a7;
    background-color: #e2e2e238;
    border-radius: 5px;
}
input:active,
input:focus{
    border: 1px solid #a7a7a7;
    background-color: #e2e2e238; 
}
.formBox{
    display: inline-block;
    /* align-items: center;
    justify-content: center; */
    background-color: transparent;
    padding-top: 10px;
}
.backDrop{
    width: 100%;
    height: 130px;
    background-color: #F4F3F3;
    z-index: 1;
    border-radius: 10px;
}
.OuterProfile{
    position: relative;
    padding-bottom: 15px;
}
.topProfile{
    position: absolute;
    top: -150px;
    width: 100%;
    display: flex;
    align-items: center;
justify-content: center;
    /* padding-left: 14%; */
    /* gap: 20px; */
    overflow: hidden;
    padding-bottom: 5px;
    z-index: 100;
    /* padding-top: 100px; */
  }
  .profilepicture {
    width: 150px; 
    height: 150px;
    border-radius: 50%; 
    border: 2px solid #c6c6c6;
    overflow: hidden; 
    display: flex;
    justify-content: center;
  
    align-items: center;
  }
  .profilepicture img {
    width: 150px;
    height: 150px;
    object-fit: cover; 
    border-radius: 50%; 
  } 
  .whole{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 10%;
  }
  .one{
    align-items: center;
    justify-content: center;
}
.saving{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 45px;
}
.OuterContainer{
    width: fit-content;
}
.cancel,
.save{
    padding: 5px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.cancel{
    background-color: #bebebe
    ;
    color: #3b3b3b;
}
.save{
    background-color: #256FFF;
    color: #fff; 
}





.profilepicture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 2px solid #c6c6c6;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .profilepicture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 50%;
  }
  
  .profilepicture:hover .overlay {
    opacity: 1;
  }
  
  .cameraIcon {
    font-size: 24px;
    margin-bottom: 5px;
  }
  
  .uploadText {
    font-size: 14px;
  }
  
  .fileInput {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
  }
  