.PhoneInputInput{
    width: 304px;
    height: 49px;
    padding-left: 10px;
    border: 0.5px solid #88868675;
}

.PhoneInput{
    margin-left: 6px;
}

.PhoneInput--focus{
    border:none;
    outline: none;
}
