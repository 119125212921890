.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 30vh;
  left: 10%;
  width: 60%;
  z-index: 100;
  overflow: hidden;
}

.header {
  /* background: #417EDA; */
  /* background: #4274F6; */
  background: none;
  padding: 1rem;
  font-weight: 500;
  color: #424242;
}

.header h3 {
  margin: 0;
  font-size: 20px;
}

.content {
  padding: 1rem;
}

.content input {
  padding: 10px;
  width: 300px;
  margin: 2px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
}

.actions {
  padding: 1rem;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.cancel{
  font: inherit;
  border: 1px solid #787878;
  background: none;
  color: #424242;
  padding: 0.25rem 1rem;
  cursor: pointer;
  border-radius: 5px;
}
.errorMessage{
  font-size: 14px;
  color: #a91c1c;
}
.create {
  font: inherit;
  border: 1px solid #4274F6;
  background: #4274F6;
  color: white;
  padding: 0.25rem 1rem;
  cursor: pointer;  
  border-radius: 5px;


}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 30rem;
  }
}