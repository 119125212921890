* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* overflow-x: hidden; */
}

body {
 font-family: "Cabin", sans-serif;
 background-color: #fff;
 overflow-x: hidden;
}
.service{
  display: grid;
  grid-template-columns: 1fr 5fr ;
  overflow: hidden;
  height: 100vh;
}
.maintab{
  overflow-y: scroll;
  /* background-color: #818b9669; */
}
.sidetab{
  /* border: 0.5px solid #949494;
  background: linear-gradient(195deg, #FFF 5.84%, rgba(155, 155, 155, 0.38) 92.8%); */
  background: #8f8f8f33;
  /* display: flex;
  flex-direction: column;   */
  overflow: hidden;
}

.profilepicture{
  height: 150px;
}
.smalltabs{
  /* position:absolute; */
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;    
  justify-content: center;
}

.eachtab:hover {
  background-color: #ababab87;
  color: #000;
  padding: 18px;
  overflow: hidden;
  width: 78%;
  border-radius: 19px;

  margin-bottom: 15px;
}
.activeTab {
  background-color: #ababab87;
  color: #000;
  padding: 18px;
  overflow: hidden;
  width: 78%;
  border-radius: 19px;
  margin-bottom: 15px;
}

.eachtab{

  color: #000;
  padding: 18px;
  overflow: hidden;
  width: 78%;
  border-radius: 19px;

  margin-bottom: 15px;
  cursor: pointer;
}
.eachtab li {
  list-style: none;
}
.eachtab li a{
  text-decoration: none;
  color: #424242;
}

.topProfile{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-left: 14%; */
  gap: 20px;
  overflow: hidden;
  padding-bottom: 5px;
  padding-top: 30px;
}

.profilepicture {
  width: 80px; 
  height: 80px;
  /* background-color: #48a4bf;  */
  border-radius: 50%; 
  border: 2px solid #adadad;
  overflow: hidden; 
  display: flex;
  justify-content: center;

  align-items: center;
}

.profilepicture img {
  width: 80px;
  height: 80px;
  object-fit: cover; 
  border-radius: 50%; 
}
.Home {
  overflow: hidden;
  text-align: start;
  /* background: #ebebebb5; */
  padding-left: 5%;
  padding-top: 75px;
  color: #424242;;
}
.Projects {
  overflow: hidden;
  text-align: start;
  /* background: #ebebebb5; */
  /* background-color: #edededd9; */
  /* padding-bottom: 100px; */
  padding-left: 5%;
  padding-top: 30px;
  color: #424242;;
}
.Home {
  overflow: hidden;
  text-align: start;
  /* background: #ebebebb5; */
  /* padding-left: 10%; */
  /* padding-top: 100px; */
  color: #424242;;
}
.first_client{
  margin-top: 5%;
  width: 800px;
  height: 74px;
  color: #000;

  /* background-color: #f7f7f7; */
  display: flex;
  align-items: center;
  border-radius: 26px;
  justify-content: flex-start;
  cursor: pointer;
  /* border: 1px solid #0392bd; */
  border: 1px solid #256fff;
}
.first_client p{
  padding-left: 2%;
}
.first_client:hover{
  background-color: #256fff;
  color: #fff;
  font-weight: 600;
  border: none;
}
.least:hover{
  color: #fff;
}
.first_client{
  padding-left: 2%;
}
.icon{
width: 25px;
height: 25px;
padding-right: 20px;
}