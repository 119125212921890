* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.loginBoxGlobal .input_group {
  text-align: left;
}

/* .loginBoxGlobal label{
  font-family: 'Roboto', sans-serif;
  display: block;
  font-size: 16px;
  font-weight: 300;
  color: #424242; 
  margin-bottom: 5px;
}
.loginBoxGlobal label p{
  color: #424242; 
} */

.loginBoxGlobal .Logintext p{
  text-align: center;
  padding-top: 10%;
  font-size: 28px;
  color: #424242; 
  padding-bottom: 30px;
}
.loginBoxGlobal .input_field {
  width: 100%;
  padding: 18px;
  margin-bottom: 20px;
  border: none;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  color: #000;
  border: -19.9px solid rgb(255 255 255 / 31%);
}
.loginBoxGlobal .input_field:focus {
  background: rgba(255, 255, 255, 0.7); 
  border-color: #007bff; 
  outline: none; 
}

.loginBoxGlobal .login_button {

  width: 88%;
  padding: 12px;
  margin-top: 26px;
  border-radius: 10px;
  border: 2px solid #06F;
  background: #0057FF;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  transition: background 0.3s;
}
.loginBoxGlobal .login_button:hover {
  background: #0056b3;
  border: 2px solid #0056b3;
}

.loginBoxGlobal .newuser{
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 10%;
}
.loginBoxGlobal .newuser p{
  color: rgb(0 0 0);
}
.loginBoxGlobal .newuser a{
  cursor: pointer;
  font-weight: 400;
}
.loginBoxGlobal .newuser a{
  text-decoration: none;
}
.loginBoxGlobal {
  width: 428px;
  height: 570px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2); 
  backdrop-filter: blur(10px); 
  border-radius: 10px;
  padding: 0px 58px 0px 58px;
  text-align: center;
}
.loginBoxGlobal .input_group {
  text-align: left;
}

.loginBoxGlobal label {
  font-family: 'Roboto', sans-serif;
  display: block;
  font-size: 16px;
  font-weight: 300;
  color: #424242; 
  margin-bottom: 5px;
}

.loginBoxGlobal .input_field {
  width: 100%;
  padding: 18px;
  margin-bottom: 20px;
  border: 1px solid #424242;
  border-radius: 8px;
  /* background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px); */
  backdrop-filter: blur(5px);
  color: #000;
  /* border: -19.9px solid rgb(255 255 255 / 31%); */
}
.loginBoxGlobal .input_field:focus {
  background: rgba(255, 255, 255, 0.7); 
  border-color: #007bff; 
  outline: none; 
}

.loginBoxGlobal .login_button {

  width: 88%;
  padding: 12px;
  margin-top: 26px;
  border-radius: 10px;
  border: 2px solid #06F;
  background: #0057FF;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  transition: background 0.3s;
}
.loginBoxGlobal .login_button:hover {
  background: #0056b3;
  border: 2px solid #0056b3;
}

.loginBoxGlobal .forgotpass{
  text-align: right;
}
.loginBoxGlobal .forgotpass a{
  font-weight: 300;
  text-decoration: none;
  color: #0057FF;
}
.loginBoxGlobal .forgotpass p {
  color: #424242;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;; 
}
.loginBoxGlobal .newuser{
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 10%;
}
.loginBoxGlobal .newuser p{
  color: rgb(0 0 0);
}
.loginBoxGlobal .newuser a{
  cursor: pointer;
  /* color: rgb(24, 135, 39);   */
  color: #007bff;  
  font-weight: 400;
}
.loginBoxGlobal .newuser a{
  text-decoration: none;
}

.loginBoxGlobal .optionalsignup{
  padding-top: 20px;
}
.loginBoxGlobal .optionalsignup a{
  cursor: pointer;
}
.loginBoxGlobal .googleicon{
  width: 28px;
  height: 28px;
  background-color: #fff; 
  border-radius: 50%;
}
.signupOption{
  padding-top: 35px;
}
.loginBoxGlobal p{
  color: #424242;
}
.input_group .invalidStatus {
  font-size: 11px;
  padding-bottom: 2px;
  color: rgb(201, 13, 13);
}
.loginBoxGlobal .invalidStatus {
  font-size: 11px;
  padding-bottom: 2px;
  color: rgb(201, 13, 13);
}
@media screen and (max-width: 480px){
  .loginBoxGlobal{
    margin-top: 40px;
  }
}

