* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* overflow-x: hidden; */
  }
.topProject{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.addbtn{
margin: 40px;
padding-right: 60px;
}
.addclient{
    padding: 12px 18px 12px 18px;
    border: none;
    font-size: 15px;
    color: #fff;
    border-radius: 10px;
    /* background-color: #0088dd; */
    background-color: #4274F6;
    /* background-color: #057475;; */
    cursor: pointer;
}
.horizontal_line {
    border: none;
    border-top: 1px solid #a1a1a1;
    margin: 14px 0;
    width: 92%;
}
.listProjects{
    /* overflow:scroll; */
    /* background-color: violet; */
}