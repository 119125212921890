/* DesktopLogin.module.css */

.desktopLogin {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to bottom, #57a0ff, #1e81ff);
  color: white;
  margin: 0;
  font-family: Arial, sans-serif;
}

.desktopLoginLogo {
  position: absolute;
  top: 10px; /* Top spacing */
  left: 20px; /* Left spacing */
  /* height: 45px; 
  width: 105px;  */
  max-width: 150px;
}

.desktopLoginContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.googleIconContainer {
  margin-bottom: 20px;
}

.googleIconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.googleIcon {
  width: 30px; /* Google icon width */
  height: 30px; /* Google icon height */
}

.desktopLoginStatus {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 10px;
}
