* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.637);
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  height: 750px;
  width: 32%;
  z-index: 100;
  overflow: hidden;

}

.header {
  background: none;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #424242;
}

.horizontalLine {
  border-top: 1px solid #D2D2D2;
  margin: 2px 0;
}

.header h3 {
  margin: 0;
  padding-left: 20px;
  font-size: 20px;
}

.header img {
  cursor: pointer;
}
.ContentShell  .email{
  display: flex;
  padding-top: 10px;
  gap: 10px;
}
.wholeFolders {
  width: 369px;
  margin: 0 auto;
  padding-top: 30px;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.folder {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background: rgba(217, 217, 217, 0.12);
  width: 164px;
  height: 46px;
  padding-left: 5px;
  position: relative;
}
.crossicon {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.folderImg {
  width: 24px;
  height: 24px;
  margin-top: 8px;
}
.addMore {
  border-radius: 5px;
  background: #D9D9D9;
  width: 174px;
  height: 43px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px 0px 0px 61px;
}

.ContentShell .contact {
  display: flex;
  align-items: center;
  padding-top: 10px;
  gap: 10px;

}
.wholeContent {
  border-radius: 13px;
  background: #E6E5E5;
  width: 374px;
  margin: 0 auto;
  margin-top: 41px;
  padding-left: 21px;
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.ContentShell {
  padding: 10px;
}

.content {
  padding: 1rem;
}

.content input {
  padding: 10px;
  width: 300px;
  margin: 2px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
}

.actions {
  width: 449px;
  padding: 4rem 1rem 1rem 1rem;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.cancel {
  font: inherit;
  border: 1px solid #787878;
  background: none;
  color: #424242;
  padding: 0.25rem 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.errorMessage {
  font-size: 14px;
  color: #a91c1c;
}

.create {
  font: inherit;
  border: 1px solid #256FFF;
  background: #256FFF;
  color: white;
  padding: 0.25rem 2rem;
  cursor: pointer;
  border-radius: 5px;

}

@media (min-width: 1600px) {
  .modal {
    left: calc(50% - 20rem);
    width: 30rem;
  }
}

.folderName{
  font-size: 15px;
}