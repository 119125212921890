@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.637);
  }
  
  .modal {
    position: fixed;
    top: 30%;
    left: 28%;
    height: 299px;
    width: 40%;
    z-index: 100;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .email_form_container {
    display: flex;
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
    padding-top: 20px;
  }
  
  .email_input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .send_button {
    background-color: #387ED1;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .send_button:hover {
    background-color: #1f70d2;
  }

  .title {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding-top: 30px; 
    color: #387ED1;
  }
  .title p{
    font-weight: 600;
    font-size: 16px;
    font-family: 'Lato', sans-serif; 
  }
  .text p{
    padding-top: 21px;
    font-size: 12px;
  }
  .container{
    padding: 20px 100px;
  }
  .terms_text p{
    padding-top: 20px;
    font-size: 12px;
  }
  .terms_text p a{
    text-decoration: none;
    color: #387ED1;
  }

 
  @media (min-width: 1600px) {
    .modal {
      left: calc(50% - 20rem);
      width: 30rem;
    }
  }
  
  .folderName{
    font-size: 15px;
  }