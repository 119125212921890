@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* overflow-x: hidden; */
  }
  .backbtn{
    padding-top: 20px;
    cursor: pointer;
    display: inline-block;   
  }
.container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 850px;
    gap: 10px; 
    padding: 35px 30px;
}  
.GalleryTile {
    /* display: flex;
    flex-wrap: wrap; */
    position: relative;
    width: 180px; 
    height: 180px; 
    overflow: hidden;
  }
  
.container img{
    width: 100%;
    height: 100%;
    object-fit: cover; 
}
.Download_banner{
  height: fit-content;
  width: auto; 
  margin-top: 10px;
  /* margin: 10px 20px; */
  background-color: #005BED;
  border-radius: 10px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}
.Download_banner p{
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  color: #fff;
  text-align: left;
  text-transform: capitalize;
  word-spacing: 0.2em;
  line-height: 1.5;
  letter-spacing: 0.5px;  
}
.Download_banner_inside{
  padding: 10px;
}
.Download_banner_inside p span{
  cursor: pointer;
  color: #fff;      
}
.Download_banner_inside p span a{
    text-decoration: none;
    color: #fff;
}
.Download_banner_inside p span a:hover{
  /* color: #2505f3; */
  color: #05bff3;
}
.Download_banner_outerlayer{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 10px 20px;
}
.downloadbtn_desktop{
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  color: #005BED;
  width: fit-content;
  height: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 10px 18px;
  border-radius: 10px;
  gap: 10px;
  cursor: pointer;
}
.downloadbtn_desktop a{
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #005BED;
}