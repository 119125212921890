* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* overflow-x: hidden; */
}

body {
 font-family: "Cabin", sans-serif;
  background-color: #fff;
  overflow-x: hidden;
}
.folders{
  padding: 10px 10px 10px 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  margin-top: 10px;
}
.each_folders {
  /* display: flex; */
  align-items: center;
  /* gap: 15px; */
  width: 250px;
  height: 80px; 
  padding: 0px 0px 0px 25px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #C1BABA;
  cursor: pointer;
}
.selected_count{
  font-size: 10px;
}

.folder_link {
  text-decoration: none; 
  color: #333; 
  display: flex;
  align-items: center; 
  gap: 8px; 
  font-size: 15px;
  padding-top: 13px;
}
