/* Gallery_face.css */

/* Gallery container styles */
.gallery-container-face {
  position: relative;
  max-height: 550px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
}

/* Image count styling */
.image-count-face {
  position: sticky;
  top: 0; /* Stick to the top of the container */
  background-color: #111212de;
  color: #fff;
  padding: 15px 16px; /* Adjust padding as needed */
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  z-index: 10; /* Ensure it stays above other elements */
  display: flex;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Icon styling within the image count */
.image-count-face svg {
  margin-right: 8px;
  font-size: 20px; /* Adjust icon size as needed */
}

/* Gallery grid styles */
.gallery-grid-face {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

/* Gallery item styles */
.gallery-item-face {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.gallery-item-face img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  display: block;
  border-radius: 8px;
}

.gallery-item-face img:hover {
  transform: scale(1.05);
}

/* Hide scrollbar if desired */
.gallery-container-face::-webkit-scrollbar {
  display: none;
}

.gallery-container-face {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Optional: Style for error message */
.gallery-container-face.error {
  color: red;
  text-align: center;
}
