* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container{
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
