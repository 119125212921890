@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Engagement&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    /* font-family: 'Lato', sans-serif;
    background: linear-gradient(179.85deg, rgba(190, 174, 158, 0.66) 0.13%, #71153B 210.92%);
    color: #fff; */
}

.container-invitation{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
}

.backpic {
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Great Vibes', cursive;
    text-align: center;
}
.overlay h3{
    font-size: 5rem;
}
.content {
    padding-top: 30px;
    width: 100%;
    max-width: 100%;
    padding: 30px;
    margin-top: -100px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: 'Playfair Display', serif;
}

.date, .location, .invitation, .company-logo {
    margin-bottom: 20px;
    /* width: ; */
}

.date-day {
    font-size: 4rem;
    font-family: 'Cinzel', serif;
}

.date-day span {
    font-size: 2rem;
    vertical-align: super;
}

.date-month, .date-year {
    font-size: 1.5rem;
    font-family: 'Cinzel', serif;
}

.venue {
    font-family: 'Lato', sans-serif;
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.get-directions {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.get-directions .icon {
    margin-right: 10px;
}

.get-directions:hover {
    background-color: #fff;
    color: #71153B;
}

.invitation h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-family: 'Great Vibes', cursive;
}

.invitation p {
    font-size: 2.2rem;
    font-family: 'Great Vibes', cursive;
}

.company-logo img {
    width: 70px;
}

.not-available {
    font-size: 1.5rem;
    color: #666;
    text-align: center;
    padding: 20px;
}

@media (max-width: 768px) {

    .backpic {
        height: 40vh;
    }

    .content {
        margin-top: -50px;
        padding: 10px;
        padding-top: 70px;
    }

    .date-day {
        font-size: 2rem;
    }

    .venue {
        font-size: 1.2rem;
    }

    .overlay h3{
        font-size: 3rem;
    }
}


.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 2rem;
    font-family: 'Cinzel', serif;
    color: white;
}
