* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }
  
  body {
   font-family: "Cabin", sans-serif;
   background-color: #fff;
   overflow-x: hidden;
  }
  .uploads{
    display: grid;
    grid-template-columns: 1fr 5fr ;
    height: 100vh;
    /* overflow: hidden; */
    /* overflow: hidden; */
  }
  .AlbumCover{
    /* overflow: hidden; */
    overflow: scroll;
  }