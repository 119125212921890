/* Reset and Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f0f4f8;
  color: #333;
} */

/* Container */
.form-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

.form-container {
  width: 100%;
  max-width: 400px;
  padding: 30px 25px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.form-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.form-title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 1.8rem;
  font-weight: 600;
  color: #007BFF;
}

/* Form Styling */
.user-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  position: relative;
  margin-bottom: 20px;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: #007BFF; /* Blue color for icons */
  font-size: 1rem;
}

.user-form input {
  width: 100%;
  padding: 12px 12px 12px 40px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.user-form input:focus {
  border-color: #007BFF;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  background-color: #007BFF;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.submit-button:active {
  transform: translateY(0);
}

.button-icon {
  font-size: 1.2rem;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .form-container {
    padding: 25px 20px;
  }

  .form-title {
    font-size: 1.5rem;
  }

  .user-form input {
    padding: 10px 10px 10px 35px;
  }

  .submit-button {
    padding: 10px;
    font-size: 0.95rem;
  }

  .button-icon {
    font-size: 1rem;
  }
}
