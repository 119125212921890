* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* overflow-x: hidden; */
  }
  /* Add this to your existing styles */
.custom_select_container {
    position: relative;
    display: inline-block;
    margin: 48px 0px 22px 10px;
  }
  .sourcelabel{
    color: #424242;
  }
  .custom_select {
    position: relative;
    display: flex;
    align-items: center;
    width: 350px;
    padding: 13px;
    font-size: 16px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    outline: none;
    background-color: #fff;
    cursor: pointer;
  }
  
  .dropdownArrow {
    width: 25px;
    height: 20px; 
    margin-left: auto; 
    transform: rotate(0deg); 
    transition: transform 0.3s ease; 
  }
  
  .custom_options {
    position: absolute;
    top: 100%;
    left: 0;
    overflow-y: scroll;
    height: 150px;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
    background-color: #fff;
    color: #424242;
  }
  
  .custom_options div {
    padding: 8px;
    transition: background-color 0.3s ease;
  }
  
  .custom_options div:hover {
    background-color: #a6cdf489;
  }
  