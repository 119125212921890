/* App.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

/* body {
  font-family: "Cabin", sans-serif;
  background-color: #fff;
} */

.App {
  position: relative;
}

/* Upload Queue Container */
.closeBtnProgress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  position: fixed;
  bottom: 90px; /* Adjust based on Progress component height */
  right: 28px;
  background-color: #1f45ed;
  color: #fff;
  width: 357px;
  height: 55px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.closeBtnProgress span {
  color: #fff;
  cursor: pointer;
  font-size: 20px; 
}   

.closeBtnProgress p {
  color: #fff;
  padding: 5px;
  font-size: 14px;
}

.filesQueue p {
  display: inline-block;
  font-size: 14px;
}

/* Main Progress Container */
.MainApp {
  position: fixed;
  bottom: 28px;
  right: 28px;
  z-index: 1000;
}

.Progress {
  max-height: 300px; 
  width: 357px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 10px;
  padding: 20px;
  overflow-y: auto;  
}

/* Scrollbar Styling */
.Progress::-webkit-scrollbar {
  width: 6px; 
}

.Progress::-webkit-scrollbar-track {
  background: transparent; 
}

.Progress::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); 
  border-radius: 10px; 
}

.Progress::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4); 
}

/* Responsive Design */
@media (max-width: 768px) {
  .MainApp {
    width: 90%;
    right: 5%;
  }

  .closeBtnProgress {
    width: 90%;
    right: 5%;
    bottom: 90px;
  }

  .Progress {
    width: 100%;
    max-height: 250px;
  }
}
