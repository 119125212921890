/* Progress Bar Container */
.closeBtnProgress {
  position: fixed;
  right: 20px; /* Fixed to the right with spacing */
  bottom: 250px; /* Place the header at the top of the progress bar list */
  width: 350px; /* Narrower width to keep it compact */
  height: auto; /* Automatic height based on content */
  background-color: #007bff; /* Blue background for the header */
  color: white; /* White text for contrast */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  z-index: 1001; /* Ensure it stays on top of other elements */
  border-radius: 8px 8px 0 0; /* Rounded top corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.closeBtnProgress span {
  color: #fff;
  cursor: pointer;
  font-size: 18px; /* Adjust size for better look */
}

.closeBtnProgress p {
  font-size: 14px;
  margin: 0;
  color: #fff; /* White text */
}

/* The main container for progress items */
.MainApp .Progress {
  position: fixed; /* Fixed position to bottom-right */
  right: 20px; /* Same alignment as header */
  bottom: 30px; /* Spacing from the bottom */
  width: 350px; /* Narrower width */
  max-height: 220px; /* Limit the height to keep it compact */
  background: rgba(255, 255, 255, 0.95); /* Slightly transparent background */
  border-radius: 0 0 8px 8px; /* Rounded bottom corners */
  padding: 10px; /* Padding for content */
  z-index: 1000;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Progress Bar Styling */
.progress-bar {
  height: 8px; /* Increase height for better visibility */
  padding-top: 5px;
  border-radius: 5px;
  background-color: #f0f0f0; /* Light gray background */
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.progress-bar-inner {
  height: 100%;
  border-radius: 5px;
  transition: width 0.4s ease-in-out;
  background-color: #007bff; /* Blue color for progress */
}

/* Styling for each upload task */
.EachTask {
  background: #f0f4ff; 
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 8px; /* Add space between tasks */
  /* display: flex;
  align-items: center; */
  justify-content: space-between;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.EachTask .imageIcon span {
  font-size: 18px;
  color: #007bff; /* Same color as the progress bar */
}

.EachTask .FileName {
  font-size: 14px;
  color: #333;
  flex: 1; /* Take up available space */
  margin-left: 10px;
}
.FileName p span{
  /* gap: 10px; */
  padding-right: 10px;
}
.EachTask .Progress-Status {
  color: green;
  font-size: 18px; /* Increase size for better visibility */
}

.Progressing {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Custom Scrollbar Styling */
.Progress::-webkit-scrollbar {
  width: 5px;
}

.Progress::-webkit-scrollbar-track {
  background: transparent;
}

.Progress::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.Progress::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
