/* Reset and Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}
.face_reco_profile{
  width: 80px;
  height: 80px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #d3d1d1;
  border: 1px solid #969696;
  cursor: pointer;
  margin: 40px auto;
  overflow: hidden;
}
.face_reco_profile img{
  width: 86px;
  height: 34px;
  object-fit: cover;
  border-radius: 50%;
}
.Prof_page{
  display: flex;
  flex-direction: column;
}
.Prof_page_back{
  display: absolute;
  cursor: pointer;
}
.Prof_page_back_inner{
  position: relative;
  top: 100px;
  left: 50px;
  width: 25px;
  height: 25px;
  font-size: 25px;

}
/* FaceRecognition.css */

.clickable {
  cursor: pointer;
}

/* Apply to elements */
.face_reco_profile span,
.face_reco_Home {
  /* Other styles */
}

.face_reco_Home {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 13px;
  padding: 10px;
  cursor: pointer;
} 
.face_reco_Home:hover {
  background-color: #ababab87

} 
.face_reco_Home span{
  padding: 10px;
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
  display: flex;
  gap: 20px;
}
/* Container for Face Recognition Component */
.face_reco_container {
  height: 100vh;  /* Make the container fill the full viewport height */
}

.face_reco_layer {
  display: grid;
  grid-template-columns: 200px 1fr 250px;
  height: 100%;  /* Make the grid fill the container height */
}

/* Sidebar, Main Content, and Rightbar Styles */
.face_reco_sidebar, .face_reco_main, .face_reco_rightbar {
  height: 100%;  /* Ensure each section fills the available height */
}

.face_reco_sidebar {
  /* Optional Background Colors (Commented Out) */
  /* background-color: aqua; */
  /* background-color: #e8e6e6; */
  background: #8f8f8f33;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.face_reco_main {
  /* Optional Background Color (Commented Out) */
  /* background-color: blue; */
}

.face_reco_rightbar {
  background-color: #F3F1F1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Client Title and Top Clients Section */
.clientTitle {
  margin: 2%;
  padding: 1%;
  border-radius: 15px;
  background: #F3F1F1;
  color: #555;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.topClients {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 47px;
}

.topClients span {
  padding-top: 3px;
  cursor: pointer;
}

/* Navigation Bar Styles */
.navbar {
  display: flex;
  justify-content: start;
  border-bottom: 1px solid #ccc;
  margin: 10px;
}

/* NavLink Styling */
.navbar a {
  padding: 10px 20px;
  margin-right: 20px;
  text-decoration: none;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  border-bottom: 2px solid transparent;
}

/* Active Link Styling */
.navbar a.active {
  border-bottom: 2px solid #000;
  color: #000;
}

/* Hover Effect for NavLinks */
.navbar a:hover {
  color: #000;
  border-bottom: 2px solid #ccc;
}

/* Content Container */
.content-container {
  padding: 20px;
  overflow-y: auto;
}

/* Publish, Compare, and Close Event Buttons */
.publish-button,
.close-event-button,
.compare-button {
  width: 200px;
  padding: 12px 20px;
  margin: 10px 0;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #007BFF;
  color: #fff;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Hover Effects for Buttons */
.publish-button:hover,
.close-event-button:hover,
.compare-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* Disabled State for Buttons */
.publish-button:disabled,
.close-event-button:disabled,
.compare-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  transform: none;
}

/* Differentiate the Close Event Button */
.close-event-button {
  background-color: #dc3545;
}

.close-event-button:hover {
  background-color: #c82333;
}

/* Compare Guest Faces Button Specific Styles */
.compare-button {
  background-color: #28a745; /* Green color for distinction */
}

.compare-button:hover {
  background-color: #218838;
}

/* Success and Error Messages */
.success-message {
  color: #28a745;
  margin-top: 5px;
  font-size: 14px;
}

.error-message {
  color: #dc3545;
  margin-top: 5px;
  font-size: 14px;
}

/* QR Code Container */
.qr-code-container {
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* QR Code Text */
.qr-code-text {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .publish-button,
  .close-event-button,
  .compare-button {
      width: 150px;
      font-size: 14px;
      padding: 10px 16px;
  }

  .qr-code-container {
      margin-top: 15px;
  }

  .qr-code-text {
      font-size: 12px;
  }

  .navbar a {
      padding: 8px 16px;
      margin-right: 15px;
      font-size: 14px;
  }
}



/* General button styles */
.face_reco_rightbar button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 10px; /* Add some space between buttons */
}

/* Specific styles for the "Download CSV" button */
.download-csv-button {
  background-color: #28a745; /* Green color for download button */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-csv-button:hover {
  background-color: #218838; /* Darker green on hover */
  transform: translateY(-2px); /* Slight lift effect on hover */
}

.download-csv-button:disabled {
  background-color: #ccc; /* Gray color when disabled */
  cursor: not-allowed;
}

/* Additional styles for better UX */
.download-csv-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); /* Focus outline */
}

.download-csv-button:active {
  transform: translateY(0); /* Remove lift effect on active */
}
