.button {
    font: inherit;
    border: 1px solid #256FFF;
    background: #256FFF;
    color: white;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }
  
  .button:hover,
  .button:active {
    background: #256FFF;
    border-color: #256FFF;
  }
  
  .button:focus {
    outline: none;
  }