* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow-x: hidden;
}
/* Signup.module.css */
.errorMessage span {
  color: #e83455;
  font-size: 12px;
  margin-top: 5px;
}

.signupwith{
  margin: 20px 0;
  display: flex;
  justify-content: center;

}
.password_validation{
  font-size: 5px;
  color: #0057FF;
}

.signupwithGoogle {
  background-image: url('/public/img/icons8-google.svg'); 
  height: 40px;
  width: 40px;
}

.signupwithApple {
  background-image: url('/public/img/icons8-apple-50.png');
  height: 40px;
  width: 40px;
  

}

.SignupBox {
  width: 636px;
  height: fit-content;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2); /* Set card background with transparency */
  backdrop-filter: blur(10px); /* Apply glass blur effect */
  border-radius: 10px;
  padding: 0px 58px 0px 58px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  text-align: center;
}
.input_group {
  /* text-align: left; */
  display: flex;
  flex-direction: column;
}

label {
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  font-size: 15px;
  font-weight: 300;
  text-align: left;
  color: #fff; 
  margin-bottom: 4px;
  width: 200px; 
}
label span {
  color: #fff;
}
label p{
  color: #fff;
}
/* .mob_business label{
  display: flex;
  justify-content: left;
} */
.SignupBox p {
  font-size: 17px;
  margin: 10px 0;
  color: #424242;
}
.Signuptext p{
  text-align: center;
  padding-top: 10%;
  font-size: 28px;
  color: #424242;
  padding-bottom: 16px; 
}
.input_field {
  width: 100%;
  padding: 15px;
  /* margin: 0px 0; */
  margin-bottom: 20px;
  font-size: 12px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  color: #000;
  border: 1px solid #424242;
  /* border: -19.9px solid rgb(255 255 255 / 31%); */
}
.mob_business{
  display: grid;  
  align-items: center;
  justify-content: left;
  text-align: left;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}
.input_field_name{
  width: 100%;
  padding: 15px;
  /* margin: 0px 0; */
  margin-bottom: 20px;
  font-size: 12px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  color: #424242;
  border: none;
  /* border: -19.9px solid rgb(255 255 255 / 31%); */
}
.input_field_mob{
  width: 100%;
  padding: 15px;
  /* margin: 0px 0; */
  margin-bottom: 20px;
  font-size: 12px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  color: #424242;
  /* border: none; */
  border: -19.9px solid rgba(69, 62, 62, 0.31);
}
.input_field:focus {
  background: rgba(255, 255, 255, 0.7); /* Change background color */
  border-color: #007bff; 
  outline: none; 
}

.Signup_button {
  /* width: 100%; */
  width: 88%;
  padding: 12px;
  /* margin: 10px 0; */
  margin-top: 16px;
  /* border: none; */
  /* font-size: 15px; */
  border-radius: 10px;
  border: 2px solid #10862a;
  background: #10862a;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  transition: background 0.3s;
}
.Signup_button:hover {
  border: 2px solid #0d7022;
  background: #0d7022;
}
/* .Signuptext {
  text-align: center;
  font-size: 28px;
  color: #fff;
  margin-bottom: 20px;
} */
.forgotpass{
  text-align: right;
}
.forgotpass a{
  font-weight: 300;
  text-decoration: none;
  color: #0057FF;
}
.forgotpass p {
  /* color: #2D6EEC; */
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;; /* You can use 'lighter' or a numeric value like 300 for a lighter font weight */
}
.newuser{
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 2%; 
  font-size: 15px;
}
.newuser p{
  /* color: rgb(250, 250, 250); */
  /* color: rgb(57, 57, 57); */
  color: #424242;

}
.signupOption{
  padding-top: 35px;
}
.newuser a{
  text-decoration: none;
  cursor: pointer;
  /* color: rgb(57, 57, 57); */
  color: rgb(3, 95, 24);  
  font-weight: 400;
}
.googleicon{
  width: 28px;
  height: 28px;
  background-color: #fff; 
  border-radius: 50%;
  /* padding: 5px;   */
}
.country_code{
  position: absolute;
  left: 65px;
  padding-top: 15px;
  color: #424242;
}
.optionalsignup{
  padding-top: 20px;
}
.optionalsignup a{
  cursor: pointer;
  
}

.SignupBox p {
  color: #424242;
}

.SignupBoxBoundry .invalidStatus {
  font-size: 15px;
  padding-bottom: 2px;
  color: rgb(201, 13, 13);
}

@media screen and (max-width: 480px){
  .SignupBoxBoundry{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .SignupBox {
    width: 380px;
    height: fit-content;
    padding: 0px 32px;
  }
  .input_field {
    padding: 15px; 
    margin-bottom: 10px;
    font-size: 10px;
  }
  .newuser{
    padding-top: 5%;
  }
  .SignupBox p{
    font-size: 16px;
    margin: 5px 0; 
  }
  .Signuptext p{
    color: #424242;
    font-size: 22px;
    margin: 10px 0; 
  }
  .mob_business{
    display: block;  
  }
  .newuser{
   margin-bottom: 30px;
  }
}