 * {
   margin: 0px;
   padding: 0px;
   box-sizing: border-box;
   overflow-x: hidden;
 }
 section {
  padding-top: 0px;
 }
 .Loginpage {
  display: grid;
  grid-template-columns: 1fr 600px;    
  align-items: center;
  justify-content: center;
  min-height:100vh;
 } 
  .heroText{
    position: relative;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 1%, rgba(0, 91, 237, 1) 90%);
    color: #fff;
    min-height:100vh; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .heroText p {  
    padding-bottom: 100px;
    margin: 0 auto;
    font-weight: 600;
    font-size: 58px;
  } 
  .heroLoginBox{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo{
    position: absolute;
    top: 30px;
    left: 35px;
  }

  .logo li {
    list-style: none;
}

.logo li a {
    text-decoration: none;
}

.logo img {
    max-width: 150px;
}


  @media screen and (max-width: 480px){
    .Loginpage {
      display:block;  
      align-items: center;
      justify-content: center;
      min-height:100vh;
     } 
     .heroText{
      display: none;
     }
     .logo{
      display: none;
     }
  }
  /* margin-top: 40px; */