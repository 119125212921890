



:root {
  --primary-color: #0a0a0a;
  --primary-color-dark: #2e2e2e; /* Added */
  --secondary-color: #2ecc71;
  --danger-color: #e74c3c;
  --background-color: #f9f9f9;
  --text-color: #333333;
  --card-background: #ffffff;
  --shadow-color: rgba(0, 0, 0, 0.1); 
  --hover-shadow: rgba(0, 0, 0, 0.2);
  --transition-speed: 0.3s;
  --border-radius: 10px;
  --max-width: 300px;
}

.galleryContainer {
  padding: 1rem;
  background-color: var(--background-color);
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
}
.galleryTitle_MobEmail{
  display: flex;
  align-items: center;
  justify-content: center; 
  gap: 20px;
  color: #424242;
}
.galleryHeader {
  text-align: center;
  margin-bottom: 2rem;
}

.galleryTitle {
  font-size: 5rem;
  color: var(--text-color);
  font-weight: 700;
  letter-spacing: 1px;
}
.galleryTitle_Sub{ 
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.galleryTitle_Sub p{
  font-size: 20px;
  font-weight: 300;
  padding: 10px;
  width: 500px;
  color: #424242;
  line-height: 31px;
}
.loading,
.noImages,
.error {
  text-align: center;
  font-size: 1.1rem;
  color: var(--text-color);
  margin-top: 1rem;
}

.error {
  color: var(--danger-color);
}


.galleryImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform var(--transition-speed) ease;
}

.favoriteIcon {
  color: var(--text-color);
  font-size: 1.5rem;
  transition: color var(--transition-speed) ease;
}

.favoriteIconActive {
  color: var(--danger-color);
  font-size: 1.5rem;
}

.downloadIcon {
  color: var(--primary-color);
  font-size: 1.5rem;
  transition: color var(--transition-speed) ease;
}

.downloadIcon:hover {
  color: var(--primary-color-dark); /* Updated */
}


/* ////////////////////////////////////////////// */



.galleryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjusted for cleaner, spacious layout */
  gap: 2rem; /* Increased gap for better spacing between items */
  justify-items: center;
  padding: 2rem; /* Added padding for balanced spacing around the grid */
  grid-auto-rows: 300px; /* Fixed height for uniform image aspect ratio */
}

.galleryItem {
  background-color: var(--card-background);
  /* border-radius: var(--border-radius); */
  overflow: hidden;
  box-shadow: 0 4px 12px var(--shadow-color); /* Slightly deeper shadow for a floating effect */
  transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
  width: 100%; /* Ensure items take up full width of the grid cell */
  height: 100%; /* Ensure items maintain full height based on grid row settings */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures spacing for content and controls */
}

.galleryItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px var(--hover-shadow); /* Increased hover effect shadow */
}

.imageWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-bottom: 1px solid #ddd; /* Soft divider between image and controls */
}

.galleryImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform var(--transition-speed) ease;
}

.imageWrapper:hover .galleryImage {
  transform: scale(1.05);
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: var(--card-background);
  border-top: 1px solid #ddd; /* Divider for separation of controls */
  box-sizing: border-box;
}

.actionButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  transition: transform var(--transition-speed) ease;
}

.actionButton:hover {
  transform: scale(1.1);
}

.downloadAllContainer {
  display: flex;
  justify-content: flex-end; /* Aligns the button to the right */
  margin-top: 2rem;
  padding-right: 2rem;
}

.downloadAllButton {
  background-color: var(--primary-color);
  color: #fff;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.downloadAllButton:hover {
  background-color: var(--primary-color-dark);
  transform: scale(1.05);
}


/* Responsive Typography */
@media (max-width: 1024px) {
  .galleryTitle {
    font-size: 3.5rem;
  }

  :root {
    --max-width: 280px;
  }
}

@media (max-width: 768px) {
  .galleryTitle {
    font-size: 3rem;
  }

  :root {
    --max-width: 240px;
  }
  .galleryTitle_Sub p{
    font-size: 18px;
  }
  .imageWrapper {
    width: 100%; 
  }
}

@media (max-width: 480px) {
  .galleryTitle {
    font-size: 3rem;
  }

  :root {
    --max-width: 200px;
  }
  .galleryTitle_Sub p{
    font-size: 13px;
    line-height: 25px;
  }
  .imageWrapper {
    width: 100%; 
  }
  .galleryTitle_MobEmail{
    font-size: 12px;
    gap: 10px;
  }
}



